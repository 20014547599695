import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useKeycloakToken } from '../common/useKeycloak';
import ConnectionDetails from "./ConnectionDetails";
import { addConnectionDestinations, getConnectionApiKey, getConnectionDetails, getConnectionLog, getIntegrationDetails, testConnectionAction, updateConnection } from "../../api/connections/connectionsApi";
import { Connection, ConnectionConfiguration, ConnectionLog, Destination, DestinationType, Organization, Provider, Source, buildConnection, buildDestination, buildProvider } from "../common/ObjectTypes";
import Spinner from "../common/Spinner";
import { getDestinationDetails, getDestinationsList } from "../../api/destinations/destinationsApi";
import { getSourcesForConnection } from "../../api/sources/sourcesApi";
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { ManageDestinationModal } from "../destinations/ManageDestinationModal";
import Ajv from 'ajv';
import addFormats from 'ajv-formats';
import { loadOrganizations } from "../../api/organizations/loadOrganizations";
import { page_size } from "../../api/apiUtils";
import { filterLogDate, filterLogStatus, filterLogType, logGlobalSearch, parseCursor, removeLogFilterTag, resetLogFilters } from "../activityLogs/ActivityLogsHelperFunctions";
import { changeDestinationGlobalSearch, filterDestinationOwner, filterDestinationType, filterDestinationUrl, removeDestinationFilterTag, resetDestinationFilters } from "../destinations/DestinationHelperFunctions";
import { changeSourceGlobalSearch, filterSourceUrl, removeSourceFilterTag, resetSourceFilters } from "../sources/SourceHelperFunctions";
import loadDestinationUrls from '../../api/destinations/loadDestinationUrls';
import { loadDestinationTypes } from '../../api/destinations/loadDestinationTypes';
import ConnectionDetailsOverview from "./ConnectionDetailsOverview";
import ConnectionDetailsProvider from "./ConnectionDetailsProvider";
import ConnectionDetailsDestination from "./ConnectionDetailsDestination";
import ConnectionDetailsSource from "./ConnectionDetailsSources";
import ConnectionDetailsLog from "./ConnectionDetailsLog";
import CancelChangesConfirmationModal from "./CancelChangesConfirmationModal";
import getUserPermissions from "../../api/users/getUserPermissions";
import { useTranslation } from "react-i18next";
import { useKeycloak } from "@react-keycloak/web";

export function ManageConnectionDetails() {
    type FilterTypes = {
        base_url__in: FilterTags[],
        enabled: FilterTags[],
        type__in: FilterTags[],
        owner__in: FilterTags[],
        ordering: string,
        search: string
    };
    type SourceFilterTypes = {
        destination_url__in: FilterTags[],
        enabled: FilterTags[],
        type__in: FilterTags[],
        owner__in: FilterTags[],
        ordering: string,
        search: string
    };
    type LogFilterTypes = {
        log_level: FilterTags[],
        log_type: FilterTags[],
        from_date: FilterTags[],
        to_date: FilterTags[],
        search: string,
        ordering: string
    }
    type FilterTags = {
        name: string,
        id: string
    };
    const { t } = useTranslation();
    const { connection_id } = useParams();
    const { subpage } = useParams();
    const [token, showLoginModal, setShowLoginModal] = useKeycloakToken();
    const { keycloak, initialized } = useKeycloak();
    const [canAddDestination, setCanAddDestination] = useState(false);
    const [pageLoaded, setPageLoaded] = useState(false);
    const [connection, setConnection] = useState<Connection>(buildConnection());
    const [reloadConnection, setReloadConnection] = useState(true);
    const [apiKey, setApiKey] = useState("");
    const [connectionProvider, setConnectionProvider] = useState<Provider>(buildProvider());
    const [updatedConfigurations, setUpdatedConfigurations] = useState<any[]>([]);
    const [destinationFilter, setDestinationFilter] = useState<FilterTypes>({
        base_url__in: [],
        enabled: [],
        type__in: [],
        owner__in: [],
        ordering: "",
        search: ""
    });
    const [sourceFilter, setSourceFilter] = useState<SourceFilterTypes>(() => {
        const savedFilter = localStorage.getItem('connectionSourceFilter');
        return savedFilter ? JSON.parse(savedFilter) : {
            destination_url__in: [],
            enabled: [],
            type__in: [],
            owner__in: [],
            ordering: "",
            search: ""
        }
    });
    const [logsFilter, setLogsFilter] = useState<LogFilterTypes>(() => {
        const savedFilter = localStorage.getItem('connectionLogsFilter');
        return savedFilter ? JSON.parse(savedFilter) : {
            log_type: [],
            log_level: [],
            from_date: [],
            to_date: [],
            search: "",
            ordering: ""
        }
    })
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [destDetailsModal, setDestDetailsModal] = useState(false);
    /* 
        CONNECTION DESTINATIONS:
            - "destinations" array is for destinations already associated with the connection
            - "existingDestPhase0" is for existing destinations that are available to add and unselected
            - "existingDestPhase1" is for existing destinations that have been checked and selected to add (yet to be sorted)
            - "existingDestPhase2" is for selected destinations that are sorted to the top of the available list so they remain there
            regardless of search/filter results
            - "existingDestPhase3" is for unselected destination that are still in their sorted position at the top of the available list
    */
    const [destinations, setDestinations] = useState<Destination[]>([]);
    const [existingDestPhase0, setExistingDestPhase0] = useState<Destination[]>([]);
    const [existingDestPhase1, setExistingDestPhase1] = useState<Destination[]>([]);
    const [existingDestPhase2, setExistingDestPhase2] = useState<Destination[]>([]);
    const [existingDestPhase3, setExistingDestPhase3] = useState<Destination[]>([]);
    const [reloadDestinations, setReloadDestinations] = useState(false);

    const [reloadLogs, setReloadLogs] = useState(false);
    const [selectedTimezone, setSelectedTimezone] = useState<any>({ label: "UTC", value: "+0000" });
    const [logs, setLogs] = useState<ConnectionLog[]>([]);
    const destinationOffset = 0;
    const [nextLogsCursor, setNextLogsCursor] = useState<string | null>(null);
    const [prevLogsCursor, setPrevLogsCursor] = useState<string | null>(null);
    const [selectedCursor, setSelectedCursor] = useState<string | null>(null);

    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [loadingOrganizations, setLoadingOrganizations] = useState(false);
    const [loadMoreOrganizations, setLoadMoreOrganizations] = useState(true);
    const [organizationOffset, setOrganizationOffset] = useState<number>(0);
    const [orgOverviewOffset, setOrgOverviewOffset] = useState<number>(0);

    const [sources, setSources] = useState<Source[]>([]);
    const [totalSources, setTotalSources] = useState<number>(0);
    const [sourcesOffset, setSourcesOffset] = useState<number>(0);
    const [reloadSources, setReloadSources] = useState(false);

    const [destinationUrls, setDestinationUrls] = useState<any[]>([]);
    const [destinationUrlOffset, setDestinationUrlOffset] = useState<number>(0);
    const [loadingDestinationUrls, setLoadingDestinationUrls] = useState(false);
    const [loadMoreDestinationUrls, setLoadMoreDestinationUrls] = useState(true);
    const [destinationTypes, setDestinationTypes] = useState<DestinationType[]>([]);
    const [destinationTypesOffset, setDestinationTypesOffset] = useState<number>(0);
    const [loadingDestinationTypes, setLoadingDestinationTypes] = useState(false);
    const [loadMoreDestinationTypes, setLoadMoreDestinationTypes] = useState(true);

    const [changesMade, setChangesMade] = useState(false);
    const [addDestModal, setAddDestModal] = useState(false);
    const [addExistingDest, setAddExistingDest] = useState(false);
    const [updatingDestinations, setUpdatingDestinations] = useState(false);
    const [errors, setErrors] = useState({})
    const [rsjfErrors, setRsjfErrors] = useState<any[]>([]);
    const [testActionLoading, setTestActionLoading] = useState(false);
    const [testActionData, setTestActionData] = useState(null);
    const [testActionStatus, setTestActionStatus] = useState<number>(0);
    const navigate = useNavigate();

    const loadDestinationURlsData = async (offset: number, search_str: string) => {
        if (token && typeof token === 'string' && !keycloak.isTokenExpired()) {
            const [dest_urls, total_urls, error] = await loadDestinationUrls(token, offset, search_str, destinationUrls);
            setLoadingDestinationUrls(true);
            if (!error) {
                setDestinationUrls([...new Set([...destinationUrls, ...dest_urls])]);
                if (offset + page_size >= total_urls) {
                    setLoadMoreDestinationUrls(false);
                }
                setLoadingDestinationUrls(false);
            }
        }
    }

    const loadOrganizationsData = async (offset: number, search_str: string) => {
        if (token && typeof token === 'string' && !keycloak.isTokenExpired()) {
            const [orgs, total_orgs, error] = await loadOrganizations(token, offset, search_str, organizations);
            setLoadingOrganizations(true);
            if (!error) {
                setOrganizations(orgs);
                if (offset + page_size >= total_orgs) {
                    setLoadMoreOrganizations(false);
                }
                setLoadingOrganizations(false);
            }
        }
    }

    const loadDestinationTypesData = async (offset: number, search_str: string) => {
        if (token && typeof token === 'string' && !keycloak.isTokenExpired()) {
            const [dest_types, total_dest_types, error] = await loadDestinationTypes(token, offset, search_str, destinationTypes);
            setLoadingDestinationTypes(true);
            if (!error) {
                setDestinationTypes(dest_types);
                if (offset + page_size >= total_dest_types) {
                    setLoadMoreDestinationTypes(false);
                }
                setLoadingDestinationTypes(false);
            }
        }
    }

    useEffect(() => {
        localStorage.setItem('connectionSourceFilter', JSON.stringify(sourceFilter));
        localStorage.setItem('connectionLogsFilter', JSON.stringify(logsFilter));
        const loadPerms = async () => {
            if (token && typeof token === 'string' && !keycloak.isTokenExpired()) {
                try {
                    const temp = await getUserPermissions(token);
                    setCanAddDestination(temp);
                } catch (err) {
                    console.log(err)
                }
            }
        }

        if (!pageLoaded) {
            loadApiKey();
            loadPerms();
            loadDestinationURlsData(destinationUrlOffset, "");
            loadConnectionDetails();
            loadDestinationTypesData(destinationTypesOffset, "");
        }

        if (reloadConnection) loadConnectionDetails();
        if (reloadDestinations) loadDestinations([...destinations, ...existingDestPhase1, ...existingDestPhase2]);
        if (reloadLogs) loadConnectionLogs(destinations);
        if (reloadSources) loadConnectionSources(connectionProvider.id);
        loadOrganizationsData(organizationOffset, "");
    }, [token, reloadDestinations, reloadLogs, reloadSources, reloadConnection]);

    useEffect(() => {
        if (subpage === 'provider') {
            loadConnectionDetails();
        }
    }, [subpage])

    // LOAD FUNCTIONS
    const loadConnectionDetails = async () => {
        if (token && typeof token === 'string' && !keycloak.isTokenExpired() && connection_id) {
            try {
                const tempConnection = await getConnectionDetails(token, connection_id);
                setDestinations(tempConnection.destinations);
                setConnection(tempConnection);
                setDestinationFilter(filterDestinationOwner({
                    target: {
                        "name": tempConnection.owner.name,
                        "value": tempConnection.owner.id,
                    }
                }, destinationFilter));

                await Promise.all([
                    loadConnectionSources(tempConnection.id),
                    loadConnectionProvider(),
                    subpage === "logs" && loadConnectionLogs(tempConnection.destinations),
                    loadDestinations(tempConnection.destinations)
                ]);
                setReloadConnection(false);
                setPageLoaded(true);
            } catch (err) {
                console.log(err);
            }
        }
    }

    const loadApiKey = async () => {
        if (token && typeof token === 'string' && !keycloak.isTokenExpired() && connection_id) {
            const tempKey = await getConnectionApiKey(token, connection_id);
            setApiKey(tempKey.api_key);
        }
    }

    // load all destinations, set availableDestinations as all destinations minus the ones already associated with this connection
    const loadDestinations = async (destArray: Destination[]) => {
        if (token && typeof token === 'string' && !keycloak.isTokenExpired()) {
            setExistingDestPhase2(existingDestPhase2.concat(existingDestPhase1));
            setExistingDestPhase3(existingDestPhase2.concat(existingDestPhase1));
            setUpdatingDestinations(true);
            try {
                const destinationFilterQuery = {
                    base_url__in: destinationFilter.base_url__in.map(a => a.id).toString(),
                    enabled: destinationFilter.enabled.map(a => a.id).toString(),
                    type__in: destinationFilter.type__in.map(a => a.id).toString(),
                    owner__in: destinationFilter.owner__in.map(a => a.id).toString(),
                    ordering: destinationFilter.ordering,
                    search: destinationFilter.search,
                    offset: destinationOffset,
                    previous: process.env.REACT_APP_BASE_URL_LOCAL + "/integrations/?pager=limit&limit=" + page_size + "&offset=" + (destinationOffset - page_size),
                    next: process.env.REACT_APP_BASE_URL_LOCAL + "/integrations/?pager=limit&limit=" + page_size + "&offset=" + (destinationOffset + page_size)
                }
                const temp = await getDestinationsList(token, destinationFilterQuery);

                // check to see if destination in temp.result is in the selected array
                const tempDestArray: Destination[] = temp.results.filter((b: Destination) => !destArray.map((a: Destination) => a.id).includes(b.id))

                setExistingDestPhase0(tempDestArray);
                setExistingDestPhase1([]);
                setUpdatingDestinations(false);
                setReloadDestinations(false);
            } catch (err) {
                console.log(err)
            }
        }
    }

    const loadConnectionLogs = async (destination_ids: Destination[]) => {
        let id_array: string[] = destination_ids.map(a => a.id);
        if (token && typeof token === 'string' && !keycloak.isTokenExpired() && connection_id) {
            try {
                id_array.push(connection_id);
                const log_query = {
                    cursor: selectedCursor,
                    integration__in: id_array.toString(),
                    ordering: logsFilter.ordering,
                    log_level: logsFilter.log_level.map(a => a.id).toString(),
                    log_type: logsFilter.log_type.map(a => a.id).toString(),
                    from_date: logsFilter.from_date.length > 0 ? `${logsFilter.from_date[0].id.replace('Z', '')}${selectedTimezone.value}` : "",
                    to_date: logsFilter.to_date.length > 0 ? `${logsFilter.to_date[0].id.replace('Z', '')}${selectedTimezone.value}` : "",
                    search: logsFilter.search
                }
                const temp = await getConnectionLog(token, log_query);
                setLogs(temp.results);
                const next_cursor = parseCursor(temp.next);
                const prev_cursor = parseCursor(temp.previous);
                setNextLogsCursor(next_cursor);
                setPrevLogsCursor(prev_cursor);
                setReloadLogs(false);
            } catch (err) {
                console.log(err)
            }

        }
    }

    const loadConnectionSources = async (provider_id: string) => {
        if (token && typeof token === 'string' && !keycloak.isTokenExpired()) {
            try {
                const sourceQuery = {
                    search: sourceFilter.search,
                    destination_url__in: sourceFilter.destination_url__in.map(a => a.id).toString(),
                    offset: sourcesOffset,
                    previous: process.env.REACT_APP_BASE_URL_LOCAL + "/sources/?pager=limit&limit=" + page_size + "&offset=" + (sourcesOffset - page_size),
                    next: process.env.REACT_APP_BASE_URL_LOCAL + "/sources/?pager=limit&limit=" + page_size + "&offset=" + (sourcesOffset + page_size)
                }
                const temp = await getSourcesForConnection(token, provider_id, sourceQuery);
                setSources(temp.results);
                setTotalSources(temp.count);
                setReloadSources(false);
            } catch (err) {
                console.log(err);
            }
        }
    }

    const loadConnectionProvider = async () => {
        if (token && typeof token === 'string' && !keycloak.isTokenExpired() && connection_id) {
            const tempIntegration = await getIntegrationDetails(token, connection_id);
            setConnectionProvider(tempIntegration)
        }
    }

    // SELECT SUB PAGE ON CONNECTION DETAILS PAGE
    function handleSelectSubPage(e: string) {
        if (e === "logs") setReloadLogs(true);
        navigate("/connections/" + connection_id + "/" + e)
    }

    // HANDLE SAVE CHANGE METHODS
    function handleChangeConnectionName(e: any) {
        setConnectionProvider((prevProvider: Provider) => ({
            ...prevProvider,
            name: e.target.value,
        }))
        setChangesMade(true);
    }

    function handleChangeConnectionOwner(e: any) {
        setConnection((prevConnection: any) => ({
            ...prevConnection,
            owner: {
                id: e.value,
                name: e.label,
                description: ""
            },
        }))
        setChangesMade(true);
    }

    function handleSearchOrgDropdown(e: any) {
        if (e.length > 1) loadOrganizationsData(orgOverviewOffset, e);
    }

    function handleLoadMoreOrgs() {
        setOrgOverviewOffset(orgOverviewOffset + page_size)
        loadOrganizationsData(orgOverviewOffset + page_size, "");
    }

    const handleChangeOrganizationSearchBar = (e: any) => {
        if (e.target.value.length >= 3 || e.target.value.length === 0) {
            loadOrganizationsData(organizationOffset, e.target.value);
        }
    }

    const handleLoadMoreOrganizations = () => {
        setOrganizationOffset(organizationOffset + page_size);
        loadOrganizationsData(organizationOffset + page_size, "");
    }

    function handleChangeProviderEndpoint(e: any) {
        setConnectionProvider((prevProvider: Provider) => ({
            ...prevProvider,
            base_url: e.target.value,
        }))
        setChangesMade(true);
    }

    async function handleToggleProviderEnabled(e: any) {

        // setChangesMade(true);
        const updatedConnection = {
            "enabled": e,
        }
        if (token && typeof token === 'string' && !keycloak.isTokenExpired()) {
            try {
                await updateConnection(token, connectionProvider.id, updatedConnection);
                toast.success(`${t("Connection updated")}`, {
                    position: toast.POSITION.TOP_RIGHT
                });
                setConnectionProvider((prevProvider: Provider) => ({
                    ...prevProvider,
                    enabled: e,
                }))
                setReloadConnection(true);
                navigate("/connections/" + connection_id + "/" + subpage);
                setChangesMade(false);
            } catch (error) {
                console.log(error)
            }

        }
    }

    function handleChangeProviderConfigurations(e: any, action: string) {
        const config_id = connectionProvider.configurations.find((a: ConnectionConfiguration) => a.action.id === action)
        let newConfig: any;
        if (config_id) {
            newConfig = {
                "id": config_id.id,
                "action": action,
                "data": e
            }
        } else {
            newConfig = {
                "action": action,
                "data": e
            }
        }

        if (e) {
            const index_to_find = updatedConfigurations.findIndex((a: any) => a.action === action)
            if (index_to_find === -1) {

                // if this is a new config id, push it to the array
                setUpdatedConfigurations((prevArray: any[]) => [...prevArray, newConfig])
            } else {

                // if it is an existing config id, replace the old config with the new one
                const tempArray = updatedConfigurations
                tempArray[index_to_find] = newConfig
                setUpdatedConfigurations(tempArray)
            }
        }

        setChangesMade(true);
    }

    const handleTestAction = async (action_id: string, action_type: string) => {
        if (token && typeof token === 'string' && !keycloak.isTokenExpired()) {
            setTestActionLoading(true);
            setTestActionStatus(0);
            // configuration form data for the relevant action
            let form_data = {}
            // find the index in updatedConfigurations that matches the action_id
            let updated_config_index = updatedConfigurations.findIndex((a: any) => a.action === action_id)

            // save the data from the updatedConfigurations array if it exists
            if (updated_config_index !== -1) {
                form_data = updatedConfigurations[updated_config_index].data
            } else {
                form_data = connectionProvider.configurations.findIndex((a: ConnectionConfiguration) => a.action.id === action_id) !== -1 ? connectionProvider.configurations.find((a: ConnectionConfiguration) => a.action.id === action_id).data : {}
            }

            let result = await testConnectionAction(token, connection.provider.id, action_type, { config_overrides: Object(form_data) });

            if (result) {
                let { data: resp_data, status } = result;
                setTestActionData(resp_data);
                setTestActionStatus(status);
            }
            setTestActionLoading(false);
        }
    }

    const formIsValid = () => {
        let form_errors: any = {};
        let rsjf_errors: any = {};
        // validate the basic form
        if (connectionProvider.name.length === 0) form_errors.provider_name = "Name is required."
        if (connection.owner.name === "") form_errors.provider_owner = "Select an organization."

        // validate the json form
        setErrors(form_errors);
        updatedConfigurations.forEach((config: any, index: number) => {
            const schema_index = connectionProvider.type.actions.findIndex((a: any) => a.id === config.action);
            const ajv = new Ajv({ allErrors: true, verbose: true });
            ajv.addKeyword({
                keyword: 'is_executable',
                validate: function () {
                    return true;
                },
                errors: false
            });
            addFormats(ajv);

            const schema = connectionProvider.type.actions[schema_index].schema;
            const validate = ajv.compile(schema);
            const data = { ...config.data };

            if (schema.properties && typeof schema.properties === 'object') {
                Object.keys(schema.properties).forEach((key) => {
                    if (!data.hasOwnProperty(key)) {
                        data[key] = null;
                    } else {
                        if (data[key] === undefined) {
                            data[key] = null; // or any default value you prefer
                        }
                    }
                });
            } else {
                console.error('schema.properties is not defined or is not an object');
            }

            const valid = validate(data);
            if (!valid) {
                validate.errors?.forEach((error: any) => {
                    console.error(`Error in field '${error.instancePath}': ${error.message}`);
                    const field = error.instancePath.slice(1);
                    if (!rsjf_errors[connectionProvider.type.actions[schema_index].id]) {
                        rsjf_errors[connectionProvider.type.actions[schema_index].id] = {};
                    }
                    rsjf_errors[connectionProvider.type.actions[schema_index].id][field] = `Error in field '${field}': ${error.message}`;
                });
            }
        });

        setRsjfErrors(rsjf_errors);

        return Object.keys(form_errors).length === 0 && Object.keys(rsjf_errors).length === 0;
    }

    async function handleUpdateConnection() {
        const updatedConnection = {
            "name": connectionProvider.name,
            "owner": connection.owner.id,
            "type": connectionProvider.type.id,
            "base_url": connectionProvider.base_url,
            "enabled": connectionProvider.enabled,
            "configurations": updatedConfigurations
        }
        if (!formIsValid()) {
            setChangesMade(false);
            return;
        }
        if (token && typeof token === 'string' && !keycloak.isTokenExpired()) {
            try {
                await updateConnection(token, connectionProvider.id, updatedConnection);
                toast.success(`${t("Connection updated")}`, {
                    position: toast.POSITION.TOP_RIGHT
                });
                navigate("/connections/" + connection_id + "/" + subpage);
                setChangesMade(false);
            } catch (error) {
                console.log(error)
            }

        }
    }

    function handleCancelConnectionChanges() {
        if (changesMade) {
            setShowConfirmationModal(true);
        } else {
            navigate("/");
            setChangesMade(false);
        }
    }

    const handleConfirmNavigation = () => {
        setShowConfirmationModal(false);
        setChangesMade(false);
        navigate("/");
    };

    const handleCancelNavigation = () => {
        setShowConfirmationModal(false);
    };

    // DESTINATION FILTERING FUNCTIONS
    const handleChangeDestinationUrlSearchBar = (e: any) => {
        if (e.target.value.length >= 3 || e.target.value.length === 0) {
            loadDestinationURlsData(destinationUrlOffset, e.target.value);
        }
    }

    const handleLoadMoreDestinationUrls = () => {
        setDestinationUrlOffset(destinationUrlOffset + page_size);
        loadDestinationURlsData(destinationUrlOffset + page_size, "");
    }

    function handleChangeGlobalDestinationSearch(searchSubStr: string) {
        setDestinationFilter(changeDestinationGlobalSearch(searchSubStr, destinationFilter));
        setReloadDestinations(true);
    }

    function handleFilterDestinationOwner(e: any) {
        setDestinationFilter(filterDestinationOwner(e, destinationFilter));
        setReloadDestinations(true);
    }

    function handleFilterDestinationUrl(e: any) {
        let updatedArray = filterDestinationUrl(e, destinationFilter);
        setDestinationFilter(updatedArray)
        setReloadDestinations(true);
    }

    const handleChangeDestinationTypeSearchBar = (e: any) => {
        if (e.target.value.length >= 3 || e.target.value.length === 0) {
            loadDestinationTypesData(destinationTypesOffset, e.target.value);
        }
    }

    const handleLoadMoreDestinationTypes = () => {
        setDestinationTypesOffset(destinationTypesOffset + page_size);
        loadDestinationTypesData(destinationTypesOffset + page_size, "");
    }

    function handleFilterDestinationType(e: any) {
        setDestinationFilter(filterDestinationType(e, destinationFilter));
        setReloadDestinations(true);
    }

    function handleRemoveDestinationFilterTag(e: any) {
        setDestinationFilter(removeDestinationFilterTag(e, destinationFilter));
        setReloadDestinations(true);
    }

    function handleResetDestinationFilters() {
        setDestinationFilter(resetDestinationFilters());
        setReloadDestinations(true);
    }

    // ADD AND REMOVE DESTINATIONS
    const handleClickAddDestination = (existing: boolean) => {
        if (existing) {
            setExistingDestPhase1([])
            setExistingDestPhase2([])
            setExistingDestPhase3([])
            setAddExistingDest(!addExistingDest)
        } else {
            setAddDestModal(true);
        }
    }

    const handleSelectExistingDestination = (dest: Destination) => {
        let tempPhase0Array: Destination[] = [...existingDestPhase0];
        let tempPhase1Array: Destination[] = [...existingDestPhase1];
        let tempPhase2Array: Destination[] = [...existingDestPhase2];
        let tempPhase3Array: Destination[] = [...existingDestPhase3];

        const indexInPhase0 = tempPhase0Array.findIndex((a: Destination) => a.id === dest.id);
        const indexInPhase1 = tempPhase1Array.findIndex((a: Destination) => a.id === dest.id);
        const indexInPhase2 = tempPhase2Array.findIndex((a: Destination) => a.id === dest.id);
        const indexInPhase3 = tempPhase3Array.findIndex((a: Destination) => a.id === dest.id);

        if (indexInPhase0 !== -1) {
            if (indexInPhase1 !== -1) {
                // Remove from phase 1
                tempPhase1Array.splice(indexInPhase1, 1);
                setExistingDestPhase1(tempPhase1Array);
            } else {
                // Add to phase 1
                tempPhase1Array.push(dest);
                setExistingDestPhase1(tempPhase1Array);
            }
        }

        if (indexInPhase3 !== -1) {
            if (indexInPhase2 !== -1) {
                // Remove from phase 2
                tempPhase2Array.splice(indexInPhase2, 1);
                setExistingDestPhase2(tempPhase2Array);
            } else {
                // Add to phase 2
                tempPhase2Array.push(dest);
                setExistingDestPhase2(tempPhase2Array);
                // Remove from phase 1
                // Remove from phase 1
                tempPhase1Array.splice(indexInPhase1, 1);
                setExistingDestPhase1(tempPhase1Array);
            }
        }
    }

    const handleAddSelectedExistingDestinations = async () => {
        const destination_ids = {
            "destinations": existingDestPhase1.map(a => a.id).concat(destinations.map(a => a.id), existingDestPhase2.map(a => a.id))
        }
        setChangesMade(true);
        setUpdatingDestinations(true);
        if (token && typeof token === 'string' && !keycloak.isTokenExpired()) {
            try {
                await addConnectionDestinations(token, connection.default_route.id, destination_ids);
                setDestinations(existingDestPhase1.concat(destinations, existingDestPhase2));
                toast.success(`${t("Connection updated")}`, {
                    position: toast.POSITION.TOP_RIGHT
                });
                navigate("/connections/" + connection_id + "/" + subpage);
                setChangesMade(false);
                setExistingDestPhase0([]);
                setExistingDestPhase1([]);
                setExistingDestPhase2([]);
                setExistingDestPhase3([]);
            } catch (error) {
                console.log(error)
            }
        }

        setUpdatingDestinations(false);
        setAddExistingDest(false);
    }

    const handleAddNewDestination = async (dest: Destination) => {
        // remove dest from available dests
        let availableArray: Destination[] = [];
        existingDestPhase0.forEach((availableDest: Destination) => {
            if (availableDest.id !== dest.id) availableArray.push(availableDest)
            setExistingDestPhase0(availableArray);
        });
        // add dest to connection
        let tempArray: Destination[] = destinations;
        tempArray.push(dest);
        setDestinations(tempArray);
        const destination_ids = {
            "destinations": tempArray.map(a => a.id).concat(destinations.map(a => a.id))
        }
        setChangesMade(true);
        if (token && typeof token === 'string' && !keycloak.isTokenExpired()) {
            try {
                await addConnectionDestinations(token, connection.default_route.id, destination_ids);
                toast.success(`${t("Connection updated")}`, {
                    position: toast.POSITION.TOP_RIGHT
                });
                navigate("/connections/" + connection_id + "/" + subpage);
                setChangesMade(false);
            } catch (error) {
                console.log(error)
            }
        }
    }

    const handleRemoveDestination = async (dest: Destination) => {
        // add dest to available dests
        let availableArray: Destination[] = existingDestPhase0;
        availableArray.push(dest);
        setExistingDestPhase0(availableArray);

        // remove dest from connection
        let tempArray: Destination[] = [];
        destinations.forEach((currentDest: Destination) => {
            if (dest.id !== currentDest.id) tempArray.push(currentDest)
            setDestinations(tempArray);
        })
        const destination_ids = {
            "destinations": tempArray.map(a => a.id)
        }
        setChangesMade(true);
        if (token && typeof token === 'string' && !keycloak.isTokenExpired()) {
            try {
                await addConnectionDestinations(token, connection.default_route.id, destination_ids);
                toast.success(`${t("Connection updated")}`, {
                    position: toast.POSITION.TOP_RIGHT
                });
                navigate("/connections/" + connection_id + "/" + subpage);
                setChangesMade(false);
            } catch (error) {
                console.log(error)
            }

        }
    }

    const handleToggleDestinationDetailsModal = async (dest_id: string) => {
        if (dest_id && token && typeof token === 'string' && !keycloak.isTokenExpired()) {
            // Append dest_id to the current URL
            navigate(`/connections/${connection_id}/${subpage}/destination/${dest_id}/details`, { replace: false });
        }
        setDestDetailsModal(!destDetailsModal);
    }

    const handleToggleAddDestinationModal = async (openDest_id?: string) => {
        setAddDestModal(!addDestModal)
        if (openDest_id && token && typeof token === 'string' && !keycloak.isTokenExpired()) {
            const temp_destination = await getDestinationDetails(token, openDest_id)
            handleAddNewDestination(temp_destination);
        }
    }

    // SOURCE FILTER FUNCTIONS
    function handleChangeGlobalSourceSearch(searchSubStr: string) {
        setSourceFilter(changeSourceGlobalSearch(searchSubStr, sourceFilter));
        setSourcesOffset(0);
        setReloadSources(true);
    }

    function handleFilterSourceUrl(e: any) {
        let updatedArray = filterSourceUrl(e, sourceFilter);
        setSourceFilter(updatedArray);
        setSourcesOffset(0);
        setReloadSources(true);
    }

    function handleRemoveSearchFilterTag(e: any) {
        setSourceFilter(removeSourceFilterTag(e, sourceFilter))
        setSourcesOffset(0);
        setReloadSources(true);
    }

    function handleResetSourceFilters() {
        setSourceFilter(resetSourceFilters());
        setSourcesOffset(0);
        setReloadSources(true);
    }

    const requestSortSources = (direction: boolean, sort_by: any) => {
        console.log(direction, " ", sort_by);
    }

    const handleSourcePageClick = (e: any) => {
        setSourcesOffset(e.selected * page_size);
        setReloadSources(true);
    }

    // LOGS FILTERING AND SORTING FUNCTIONS
    const handleNextLogPageClick = () => {
        if (nextLogsCursor) {
            setSelectedCursor(nextLogsCursor);  // Set the cursor to nextCursor
            setReloadLogs(true);  // Trigger reload
        }
    }

    const handlePrevLogPageClick = () => {
        if (prevLogsCursor) {
            setSelectedCursor(prevLogsCursor);  // Set the cursor to prevCursor
            setReloadLogs(true);  // Trigger reload
        }
    }

    const handleSortLogTimestamp = (direction: boolean) => {
        setLogsFilter((prevFilter: LogFilterTypes) => ({
            ...prevFilter,
            ordering: direction ? "created_at" : "-created_at"
        }));
        setSelectedCursor(null);
        setReloadLogs(true);
    }

    const handleGlobalLogSearch = (searchSubStr: string) => {
        setLogsFilter(logGlobalSearch(searchSubStr, logsFilter));
        setSelectedCursor(null);
        setReloadLogs(true);
    }

    const handleSelectTimezone = (e: any) => {
        setSelectedTimezone(e);
    }

    const handleFilterLogType = (e: any) => {
        setLogsFilter(filterLogType(e, logsFilter, t));
        setSelectedCursor(null);
        setReloadLogs(true);

    }
    const handleFilterLogStatus = (e: any) => {
        setLogsFilter(filterLogStatus(e, logsFilter, t));
        setSelectedCursor(null);
        setReloadLogs(true);
    }

    const handleFilterLogsByDate = (e: Date, side: string) => {
        setLogsFilter(filterLogDate(e, side, logsFilter, t));
        setSelectedCursor(null);
        setReloadLogs(true);
    }

    const handleRemoveLogFilterTag = (e: any) => {
        setLogsFilter(removeLogFilterTag(e, logsFilter));
        setSelectedCursor(null);
        setReloadLogs(true);
    }

    function handleResetLogFilters() {
        setLogsFilter(resetLogFilters());
        setSelectedCursor(null);
        setReloadLogs(true);
    }

    return !pageLoaded ?
        (
            <Spinner />
        ) : (
            <div>
                {addDestModal &&
                    <ManageDestinationModal
                        onToggleModal={handleToggleAddDestinationModal}
                    />
                }
                {showConfirmationModal &&
                    <CancelChangesConfirmationModal
                        onToggleModal={handleCancelNavigation}
                        onConfirm={handleConfirmNavigation}
                    />
                }
                <div className="flex flex-col">
                    <ConnectionDetails
                        reloadConnection={reloadConnection}
                        connection={connection}
                        connectionProvider={connectionProvider}
                        subPage={subpage}
                        onSelectSubPage={handleSelectSubPage}
                        onChangeProviderEnabled={handleToggleProviderEnabled}
                    />
                    {(subpage === "overview") && <ConnectionDetailsOverview
                        connection={connection}
                        errors={errors}
                        apiKey={apiKey}
                        connectionProvider={connectionProvider}
                        organizations={organizations}
                        loadMoreOrgs={handleLoadMoreOrgs}
                        orgInputChange={handleSearchOrgDropdown}
                        onChangeConnectionName={handleChangeConnectionName}
                        onChangeConnectionOwner={handleChangeConnectionOwner}
                        onUpdateConnection={handleUpdateConnection}
                        onCancelConnectionChanges={handleCancelConnectionChanges}
                        changesMade={changesMade}
                    />}
                    {(subpage === "provider") && <ConnectionDetailsProvider
                        connection={connection}
                        rsjfErrors={rsjfErrors}
                        connectionProvider={connectionProvider}
                        selectedProviderType={connectionProvider.type}
                        onChangeProviderEndpoint={handleChangeProviderEndpoint}
                        onChangeProviderConfigurations={handleChangeProviderConfigurations}
                        onTestAction={handleTestAction}
                        testActionLoading={testActionLoading}
                        testActionResponse={testActionData}
                        testActionStatus={testActionStatus}
                        onUpdateConnection={handleUpdateConnection}
                        onCancelConnectionChanges={handleCancelConnectionChanges}
                        changesMade={changesMade}
                    />}
                    {(subpage === "destinations") && <ConnectionDetailsDestination
                        destinations={destinations}
                        existingDestPhase0={existingDestPhase0}
                        existingDestPhase1={existingDestPhase1}
                        existingDestPhase2={existingDestPhase2}
                        existingDestPhase3={existingDestPhase3}
                        onSelectDestination={handleSelectExistingDestination}
                        onRemoveDestination={handleRemoveDestination}
                        onViewDetails={handleToggleDestinationDetailsModal}
                        organizations={organizations}
                        destinationUrls={destinationUrls}
                        destinationTypes={destinationTypes}
                        onClickAddDestination={handleClickAddDestination}
                        onAddExistingDestinations={handleAddSelectedExistingDestinations}
                        updatingDestinations={updatingDestinations}
                        addExistingDest={addExistingDest}
                        onLoadMoreOrganizations={handleLoadMoreOrganizations}
                        loadingOrganizations={loadingOrganizations}
                        loadMoreOrganizations={loadMoreOrganizations}
                        onChangeOwnerSearchBar={handleChangeOrganizationSearchBar}
                        onChangeDestinationUrlSearchBar={handleChangeDestinationUrlSearchBar}
                        onChangeDestinationOwnerFilter={handleFilterDestinationOwner}
                        onChangeDestinationUrlFilter={handleFilterDestinationUrl}
                        onChangeDestinationTypeFilter={handleFilterDestinationType}
                        onChangeDestinationTypeSearchBar={handleChangeDestinationTypeSearchBar}
                        onLoadMoreDestinationTypes={handleLoadMoreDestinationTypes}
                        loadingDestinationUrls={loadingDestinationUrls}
                        loadingDestinationTypes={loadingDestinationTypes}
                        loadMoreDestinationTypes={loadMoreDestinationTypes}
                        onChangeGlobalDestinationSearch={handleChangeGlobalDestinationSearch}
                        loadMoreDestinationUrls={loadMoreDestinationUrls}
                        onLoadMoreDestinationUrls={handleLoadMoreDestinationUrls}
                        filters={destinationFilter.base_url__in
                            .concat(destinationFilter.type__in, destinationFilter.owner__in, destinationFilter.enabled)}
                        onRemoveDestinationFilterTag={handleRemoveDestinationFilterTag}
                        onResetDestinationFilters={handleResetDestinationFilters}
                    />}
                    {(subpage === "sources") && <ConnectionDetailsSource
                        sources={sources}
                        totalSources={totalSources}
                        filters={sourceFilter.destination_url__in
                            .concat(sourceFilter.type__in, sourceFilter.owner__in, sourceFilter.enabled)}
                        globalSearchStr={sourceFilter.search}
                        take={page_size}
                        offset={sourcesOffset}
                        onPageClicked={handleSourcePageClick}
                        pageLoaded={pageLoaded}
                        loadingSources={reloadSources}
                        destinationUrls={destinationUrls}
                        onChangeGlobalSourceSearch={handleChangeGlobalSourceSearch}
                        onChangeDestinationUrlSearchBar={handleChangeDestinationUrlSearchBar}
                        onChangeSearchUrlFilter={handleFilterSourceUrl}
                        onRemoveSourceFilterTag={handleRemoveSearchFilterTag}
                        onResetSourceFilters={handleResetSourceFilters}
                        requestSortSources={requestSortSources}
                        loadMoreDestinationUrls={loadMoreDestinationUrls}
                        loadingDestinationUrls={loadingDestinationUrls}
                        onLoadMoreDestinationUrls={handleLoadMoreDestinationUrls}
                    />}
                    {(subpage === "logs") && <ConnectionDetailsLog
                        logs={logs}
                        filters={logsFilter.log_level
                            .concat(logsFilter.log_type, logsFilter.from_date, logsFilter.to_date)}
                        globalSearchStr={logsFilter.search}
                        reloadLogs={reloadLogs}
                        prevCursor={prevLogsCursor}
                        nextCursor={nextLogsCursor}
                        selectedTimezone={selectedTimezone}
                        onSelectTimezone={handleSelectTimezone}
                        onNextPageClicked={handleNextLogPageClick}
                        onPrevPageClicked={handlePrevLogPageClick}
                        requestSort={handleSortLogTimestamp}
                        pageLoaded={pageLoaded}
                        onChangeLogGlobalSearch={handleGlobalLogSearch}
                        onChangeLogStatus={handleFilterLogStatus}
                        onChangeLogType={handleFilterLogType}
                        onChangeLogDate={handleFilterLogsByDate}
                        onRemoveLogFilterTag={handleRemoveLogFilterTag}
                        onResetLogFilters={handleResetLogFilters}
                    />}
                </div>
            </div>
        )
}