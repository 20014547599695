import { getDestinationTypes } from "./destinationsApi";
import { DestinationType } from "../../components/common/ObjectTypes";
import { page_size } from "../apiUtils";

export const loadDestinationTypes = async (
    keycloak_token: string,
    page_offset: number,
    destinationTypeSearchInput: any,
    destinationTypes: DestinationType[],
): Promise<[DestinationType[], number, Error | null]> => {
    let total_destination_types = 0;
    try {
        const query = {
            offset: page_offset,
            search: destinationTypeSearchInput,
            ordering: 'name',
            previous: process.env.REACT_APP_BASE_URL_LOCAL + "/integrations/types/?action_type=push&pager=limit&limit=" + page_size + "&offset=" + (page_offset - page_size),
            next: process.env.REACT_APP_BASE_URL_LOCAL + "/integrations/types/?action_type=push&pager=limit&limit=" + page_size + "&offset=" + (page_offset + page_size)
        }
        const temp = await getDestinationTypes(keycloak_token, query);
        const new_array: DestinationType[] = destinationTypes.concat(temp.results);
        const duplicates_removed: DestinationType[] = Array.from(new Set(new_array.map(obj => obj.id))).map(id => {
            return new_array.find(obj => obj.id === id)!;
        });
        const alphabetized = duplicates_removed.sort((a, b) => a.name.localeCompare(b.name));
        if (destinationTypeSearchInput.length === 0) total_destination_types = temp.count;
        return [alphabetized, total_destination_types, null]
    } catch (err: any) {
        console.log(err);
        return [[], 0, err];
    }
}
