type FilterTags = {
    name: string,
    id: string
};

export const changeDestinationGlobalSearch = (searchSubString: string, destinationFilter: any) => {
    destinationFilter.search = searchSubString;
    return destinationFilter;
}

export const filterDestinationOwner = (e: any, destinationFilter: any) => {
    let tempArray: FilterTags[] = destinationFilter.owner__in
    if (tempArray.map(a => a.id).includes(e.target.value)) {
        let removed: FilterTags[] = []
        for (var item in tempArray) {
            if (tempArray[item].id !== e.target.value) removed.push(tempArray[item])
        }
        destinationFilter[e.target.id] = removed
    } else {
        let newTag = {
            name: e.target.name,
            id: e.target.value
        }
        tempArray.push(newTag)
        destinationFilter[e.target.id] = tempArray
    }
    return destinationFilter
}

export const filterDestinationUrl = (e: any, destinationFilter: any) => {
    let tempArray: FilterTags[] = destinationFilter.base_url__in
    if (tempArray.map(a => a.id).includes(e.target.value)) {
        let removed: FilterTags[] = []
        for (var item in tempArray) {
            if (tempArray[item].id !== e.target.value) removed.push(tempArray[item])
        }
        destinationFilter[e.target.id] = removed
    } else {
        let newTag = {
            name: e.target.name,
            id: e.target.value
        }
        tempArray.push(newTag)
        destinationFilter[e.target.id] = tempArray
    }
    return destinationFilter;
}

export const filterDestinationType = (e: any, destinationFilter: any) => {
    let tempArray: FilterTags[] = destinationFilter.type__in
    if (tempArray.map(a => a.id).includes(e.target.value)) {
        let removed: FilterTags[] = []
        for (var item in tempArray) {
            if (tempArray[item].id !== e.target.value) {
                removed.push(tempArray[item])
            }

        }
        destinationFilter[e.target.id] = removed
    } else {
        let newTag = {
            name: e.target.name,
            id: e.target.value
        }
        tempArray.push(newTag)
        destinationFilter[e.target.id] = tempArray
    }
    return destinationFilter;
}

export const removeDestinationFilterTag = (e: any, destinationFilter: any) => {
    if (destinationFilter.type__in.map((a: any) => a.id).includes(e)) {
        let tempArray: FilterTags[] = destinationFilter.type__in
        let removed: FilterTags[] = []
        for (var item in tempArray) {
            if (tempArray[item].id !== e) removed.push(tempArray[item])
        }
        destinationFilter.type__in = removed
    } else if (destinationFilter.owner__in.map((a: any) => a.id).includes(e)) {
        let tempArray: FilterTags[] = destinationFilter.owner__in
        let removed: FilterTags[] = []
        for (item in tempArray) {
            if (tempArray[item].id !== e) removed.push(tempArray[item])
        }
        destinationFilter.owner__in = removed
    } else if ((destinationFilter.base_url__in.map((a: any) => a.id).includes(e))) {
        let tempArray: FilterTags[] = destinationFilter.base_url__in
        let removed: FilterTags[] = []
        for (item in tempArray) {
            if (tempArray[item].id !== e) removed.push(tempArray[item])
        }
        destinationFilter.base_url__in = removed
    } else {
        // enabled
        let tempArray: FilterTags[] = destinationFilter.enabled
        let removed: FilterTags[] = []
        for (item in tempArray) {
            if (tempArray[item].id !== e) removed.push(tempArray[item])
        }
        destinationFilter.enabled = removed
    }
    return destinationFilter;
}

export const resetDestinationFilters = () => {
    return {
        base_url__in: [],
        enabled: [],
        type__in: [],
        owner__in: [],
        ordering: "",
        search: ""
    }
}