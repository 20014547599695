import React, { useState } from "react";
import { Organization, ProviderType, NewIntegration } from "../common/ObjectTypes";
import SchemaForm from "../common/SchemaForm";
import SelectInput from "../common/SelectInput";
import { ArrowDown, ArrowRight, AttentionIcon } from "../common/ProjectIcons";
import { useTranslation } from "react-i18next";
import { checkSchema } from "../common/SchemaFormTemplates/checkSchema";

type NewConnectionStepTwoProps = {
    initialConnection: NewIntegration,
    errors: any,
    rsjfErrors: any,
    selectedProviderType: ProviderType,
    organizations: Organization[],
    loadMoreOrgs: Function,
    orgInputChange: Function,
    onChangeEndpoint: Function,
    onChangeSchemaForm: Function,
    onChangeName: Function,
    onSelectOwner: Function,
    onChangeProviderEnabled: Function,
}

const NewConnectionStepTwo = ({
    initialConnection,
    errors,
    rsjfErrors,
    selectedProviderType,
    organizations,
    loadMoreOrgs,
    orgInputChange,
    onChangeEndpoint,
    onChangeSchemaForm,
    onChangeName,
    onSelectOwner,
    onChangeProviderEnabled,
}: NewConnectionStepTwoProps) => {
    const { t } = useTranslation();
    const [showAdvanced, setShowAdvanced] = useState(false);
    return (
        <div className="mt-8">
            <div className="flex flex-col bg-white pb-16 w-full lg:w-2/3">
                {/* form errors */}
                {(Object.keys(errors).length > 0 || Object.keys(rsjfErrors).length > 0) &&
                    <div className="bg-very-light-orange py-2 px-3 rounded-sm mb-6 text-dark-gray">
                        <AttentionIcon /> {t("There are fields that require your attention. Please check and correct the errors.")}
                    </div>}
                {/* configuration actions box */}
                <div className="rounded-sm p-5 border border-gray-lines">
                    <label className="text-off-black text-lg">{t("Enter the following information to connect to your data provider")}</label>
                    <div className="flex flex-col w-1/2 mr-3">

                        {/* schema form */}
                        {/* <div className="w-1/2"> */}
                        {selectedProviderType.actions.filter((item: any) => item.type !== "push").map((item: any) => {
                            const uiSchema = item.ui_schema ? { ...item.ui_schema } : {};
                            const formTitle = item.ui_schema && item.ui_schema['ui:title'] ? item.ui_schema['ui:title'] : item.schema.title ? item.schema.title : item.name;
                            return (
                                <div key={item.id} className={checkSchema(item) ? "pb-14" : "hidden"}>
                                    {checkSchema(item) && <SchemaForm
                                        formTitle={formTitle}
                                        schema={item.schema}
                                        ui_schema={uiSchema}
                                        onChange={(e: any) => onChangeSchemaForm(e, item.id)}
                                        isEditable={true}
                                        errors={rsjfErrors[item.id]}
                                    />}
                                </div>
                            )
                        })}
                    </div>
                </div>
                {/* connection organization and connection name box */}
                <div className="rounded-sm p-5 border border-gray-lines my-6">
                    <label className="text-off-black text-lg mt-8">How would you like this connection organized?</label>
                    <div className="mt-3 flex flex-col">
                        {/* organization */}
                        <div className="flex flex-col w-1/2 mr-3 mt-1 pb-1">
                            <label
                                className="inline-block hover:cursor-pointer text-sm text-secondary-gray"
                            >
                                {t("Connection Organization")} *
                            </label>
                            <SelectInput
                                options={organizations.map((org: Organization) => {
                                    return {
                                        label: org.name,
                                        value: org.id
                                    }
                                })}
                                className={"basic-single text-black"}
                                defaultValue={{
                                    label: t("Select"),
                                    value: "Select"
                                }}
                                isDisabled={false}
                                isLoading={false}
                                isClearable={false}
                                isSearchable={true}
                                isMulti={false}
                                name={t("Organization")}
                                onChange={(e: any) => onSelectOwner(e)}
                                onMenuScrollToBottom={loadMoreOrgs}
                                handleInputChange={(e: any) => orgInputChange(e)}
                            />
                            {errors.owner && <div className="text-orange-600 py-1 rounded flex flex-row items-center"><AttentionIcon /><p className="ml-1 mt-0.5">{errors.owner}</p></div>}
                        </div>
                        {/* name */}
                        <div className="flex flex-col w-1/2 mr-3 mt-1">
                            <label
                                className="inline-block hover:cursor-pointer text-sm text-secondary-gray"
                            >
                                {t("Connection Name")} *
                            </label>
                            <input
                                id="name"
                                placeholder=""
                                value={initialConnection.name}
                                className="p-1.5 h-auto border border-neutral-gray-200 rounded"
                                onChange={(e) => onChangeName(e)}
                                disabled={false}
                            >
                            </input>
                            {errors.name && <div className="text-orange-600 py-1 rounded flex flex-row items-center"><AttentionIcon /><p className="ml-1 mt-0.5">{errors.name}</p></div>}
                        </div>
                    </div>
                    {/* checkbox */}
                    <div className="flex flex-row mt-6 items-start">
                        <input
                            className="mt-1.5 accent-dark-green cursor-pointer"
                            type="checkbox"
                            value=""
                            id="checkboxDefault"
                            checked={initialConnection.enabled}
                            onChange={(e) => onChangeProviderEnabled(e)} />
                        <div className="flex flex-col">
                            <label
                                className="inline-block mx-2"
                            >
                                {t("Activate Provider")}
                            </label>
                            <label
                                className="inline-block mx-2 text-sm text-secondary-gray"
                            >
                                {t("This connection will get data from its provider.")}
                            </label>
                        </div>
                    </div>
                </div>
                {/* advanced configurations */}
                <div>
                    <div className="mt-6 text-lg font-semibold text-secondary-gray" onClick={() => setShowAdvanced(!showAdvanced)}>
                        {showAdvanced ? <ArrowDown /> : <ArrowRight />} {t("Advanced Configurations")}
                    </div>
                    {showAdvanced &&
                        <div className="flex flex-col pt-3 pb-14 border-medium-gray">
                            <label
                                className="pt-1 inline-block hover:cursor-pointer text-sm text-secondary-gray"
                            >
                                Optional Endpoint (For Advanced Users)
                            </label>
                            <input
                                id="endpoint"
                                placeholder=""
                                value={initialConnection.base_url}
                                className="p-1.5 h-auto border border-neutral-gray-200 rounded"
                                onChange={(e) => onChangeEndpoint(e)}
                                disabled={false}
                            >
                            </input>
                            {errors.base_url && <div className="text-orange-600 bg-orange-100 py-1 px-2 rounded">{errors.base_url}</div>}
                        </div>}
                </div>
            </div>
        </div>
    );
};

export default NewConnectionStepTwo;