import { getOrganizationsList } from './organizationsApi';
import { Organization } from '../../components/common/ObjectTypes';
import { page_size } from '../apiUtils';

export const loadOrganizations = async (
    keycloak_token: string,
    page_offset: number,
    orgSearchInput: string,
    organizations: Organization[],
    org_table_page?: boolean
): Promise<[Organization[], number, Error | null]> => {
    let total_orgs = 0;
    try {
        const query = {
            offset: page_offset,
            search: orgSearchInput,
            ordering: 'name',
            previous: process.env.REACT_APP_BASE_URL_LOCAL + "/organizations/?pager=limit&limit=" + page_size + "&offset=" + (page_offset - page_size),
            next: process.env.REACT_APP_BASE_URL_LOCAL + "/organizations/?pager=limit&limit=" + page_size + "&offset=" + (page_offset + page_size)
        }
        const temp = await getOrganizationsList(keycloak_token, query);
        const new_array: Organization[] = org_table_page ? temp.results : organizations.concat(temp.results);
        const duplicates_removed: Organization[] = Array.from(new Set(new_array.map(obj => obj.id))).map(id => {
            return new_array.find(obj => obj.id === id)!;
        });
        const alphabetized = duplicates_removed.sort((a, b) => a.name.localeCompare(b.name));
        if (orgSearchInput.length === 0) total_orgs = temp.count;
        return [alphabetized, total_orgs, null]
    } catch (err: any) {
        console.log(err);
        return [[], 0, err];
    }
}