import React, { MouseEventHandler, useState } from "react";
import { Connection, ConnectionConfiguration, Provider, ProviderType } from "../common/ObjectTypes";
import SchemaForm from "../common/SchemaForm";
import Spinner from "../common/Spinner";
import { ArrowDown, ArrowRight } from "../common/ProjectIcons";
import { getButtonText, getResponseText } from "../common/TestActionFunctions";
import { useTranslation } from "react-i18next";
import { checkSchema } from "../common/SchemaFormTemplates/checkSchema";

type ConnectionDetailsProviderProps = {
    connection: Connection,
    rsjfErrors: any,
    connectionProvider: Provider,
    selectedProviderType: ProviderType,
    onChangeProviderEndpoint: Function,
    onChangeProviderConfigurations: Function,
    onTestAction: Function,
    testActionLoading: boolean,
    testActionResponse: any,
    testActionStatus: number,
    onUpdateConnection: MouseEventHandler,
    onCancelConnectionChanges: MouseEventHandler,
    changesMade: boolean,
}

const ConnectionDetailsProvider = ({
    connection,
    rsjfErrors,
    connectionProvider,
    selectedProviderType,
    onChangeProviderEndpoint,
    onChangeProviderConfigurations,
    onTestAction,
    testActionLoading,
    testActionResponse,
    testActionStatus,
    onUpdateConnection,
    onCancelConnectionChanges,
    changesMade,
}: ConnectionDetailsProviderProps) => {
    const { t } = useTranslation();
    const [isResponseVisible, setResponseVisible] = useState(false);
    const [activeActionId, setActiveActionId] = useState(null);
    const [showAdvanced, setShowAdvanced] = useState(false);

    return (
        <div className="h-full mt-8 bg-very-light-gray">
            {/* div with text */}
            <div className="flex flex-row justify-between items-center w-full">
                <label className="text-secondary-gray">{t("The following inputs connect Gundi to the account that provides the data.")}</label>
            </div>
            {/* div with configuration details */}
            <div className="flex flex-col w-full pb-52">
                {/* endpoint, dynamic form */}
                <div className="mt-3 flex flex-row">
                    {/* endpoint */}
                    <div className="flex flex-col w-1/2 mx-3 my-3">
                        {/* <div className="font-bold text-secondary-gray ">Configurations</div> */}
                        <div className="pl-8">
                            {   /* schema form */}
                            {selectedProviderType.actions.filter((item: any) => item.type !== "push").map((item: any) => {
                                const uiSchema = item.ui_schema ? { ...item.ui_schema } : {};
                                const formTitle = item.ui_schema && item.ui_schema['ui:title'] ? item.ui_schema['ui:title'] : item.name;
                                return (
                                    <div key={item.id} className={checkSchema(item) ? "pb-14" : "hidden"}>
                                        {checkSchema(item) && <SchemaForm
                                            formTitle={formTitle}
                                            schema={item.schema}
                                            ui_schema={uiSchema}
                                            formdata={connectionProvider.configurations && connectionProvider.configurations.findIndex((a: ConnectionConfiguration) => a.action.id === item.id) !== -1 ? connectionProvider.configurations.find((a: ConnectionConfiguration) => a.action.id === item.id).data : {}}
                                            onChange={(e: any) => onChangeProviderConfigurations(e, item.id)}
                                            isEditable={true}
                                            errors={rsjfErrors[item.id]}
                                        />}
                                        {item.schema.hasOwnProperty('is_executable') && item.schema.is_executable === true &&
                                            <div className="flex flex-col">
                                                <div className="flex flex-row items-center">
                                                    <button className="opacity-100 bg-light-gray hover:bg-gray-lines text-dark-gray font-semibold rounded py-2 px-4 ml-1 mr-3 shadow"
                                                        onClick={() => {
                                                            onTestAction(item.id, item.value)
                                                            setActiveActionId(item.id);
                                                        }}>
                                                        {getButtonText(item.value, t)}
                                                    </button>
                                                    {testActionLoading && activeActionId === item.id && <Spinner />}
                                                    {!testActionLoading && testActionResponse !== null && activeActionId === item.id && <p className={getResponseText(item.value, testActionResponse, t)[1] !== null ? (getResponseText(item.value, testActionResponse, t)[1] ? "bg-light-green p-2 rounded-sm text-dark-green" : "bg-very-light-orange p-2 rounded-sm text-dark-gray") : "bg-light-gray p-2 rounded-sm text-dark-gray"}>
                                                        {getResponseText(item.value, testActionResponse, t)[0]}
                                                    </p>}
                                                </div>
                                                {!testActionLoading && testActionResponse !== null && activeActionId === item.id &&
                                                    <div className="flex flex-col items-start">
                                                        <button className="ml-1" onClick={() => setResponseVisible(!isResponseVisible)}>
                                                            {t("View Details")} {isResponseVisible ? <ArrowDown /> : <ArrowRight />}
                                                        </button>
                                                        {isResponseVisible &&
                                                            <pre className={getResponseText(item.value, testActionResponse, t)[1] !== null ? (getResponseText(item.value, testActionResponse, t)[1] ? "bg-light-green p-2 rounded-sm text-dark-green" : "bg-very-light-orange p-2 rounded-sm text-dark-gray") : "bg-light-gray p-2 rounded-sm text-dark-gray"}>
                                                                {JSON.stringify(testActionResponse, null, 2)}
                                                            </pre>
                                                        }
                                                    </div>
                                                }
                                            </div>}
                                    </div>
                                )
                            })}

                            {/* advanced configurations */}
                            <div>
                                <div className="mt-6 text-lg font-semibold text-secondary-gray" onClick={() => setShowAdvanced(!showAdvanced)}>
                                    {showAdvanced ? <ArrowDown /> : <ArrowRight />} {t("Advanced Configurations")}
                                </div>
                                {showAdvanced &&
                                    <div className="ml-8 flex flex-col pb-14">
                                        <label
                                            className="inline-block hover:cursor-pointer text-sm text-secondary-gray"
                                        >
                                            Optional Endpoint (For Advanced Users)
                                        </label>
                                        <input
                                            id="endpoint"
                                            placeholder=""
                                            value={connectionProvider.base_url}
                                            className="p-1.5 h-auto border border-neutral-gray-200 rounded mb-3"
                                            onChange={(e) => onChangeProviderEndpoint(e)}
                                            disabled={false}
                                        >
                                        </input>
                                    </div>}
                            </div>

                        </div>
                    </div>
                </div>


            </div>
            {/* buttons */}
            <div className="w-full z-10 fixed bottom-0 mt-12 mr-6 py-3 w-full flex flex-row bg-white/10 backdrop-blur">
                <button className={changesMade ? "bg-dark-green hover:bg-status-green text-white px-8 py-2 font-bold rounded mr-3" :
                    "bg-light-green text-medium-gray px-8 py-2 font-bold rounded mr-3"}
                    disabled={!changesMade}
                    onClick={onUpdateConnection}>
                    Save
                </button>
                <button className={"text-secondary-gray font-bold px-3 mx-3"}
                    onClick={onCancelConnectionChanges}
                >
                    Cancel
                </button>
            </div>
        </div>
    )
};

export default ConnectionDetailsProvider;