import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useKeycloak } from '@react-keycloak/web';
import OrganizationDetails from "./OrganizationDetails";
import { getOrganizationDetails, getOrganizationMembers, removeOrganizationMember } from "../../api/organizations/organizationsApi";
import Spinner from "../common/Spinner";
import { useNavigate } from 'react-router-dom';
import { getMyDetails, getUserDetails } from "../../api/users/usersApi";
import { toast } from 'react-toastify';
import { Member, Organization, buildMember, buildOrganization } from "../common/ObjectTypes";
import { ManageOrganizationModal } from "./ManageOrganizationModal";

import { ManageRemoveMemberModal } from "./ManageRemoveMemberModal";
import { ManageMemberModal } from "../users/ManageMemberModal";
import { useTranslation } from "react-i18next";

export function ManageOrganizationDetails() {
    const { org_id } = useParams();
    const { keycloak } = useKeycloak();
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [orgMembers, setOrgMembers] = useState([]);
    const [organization, setOrganization] = useState<Organization>(buildOrganization());
    const [pageLoaded, setPageLoaded] = useState(false);
    const [myDetails, setMyDetails] = useState<any>({}); // this is to check superuser status
    const [currentMember, setCurrentMember] = useState<Member>(buildMember())
    const [updateModal, setUpdateModal] = useState(false);
    const [inviteMemberModal, setInviteMemberModal] = useState(false);
    const [updateMemberModal, setUpdateMemberModal] = useState(false);
    const [removeMemberModal, setRemoveMemberModal] = useState(false);
    const [memberid, setMemberid] = useState("");
    const [memberToUpdate, setMemberToUpdate] = useState<Member>(buildMember())

    useEffect(() => {
        const loadOrganization = async () => {
            if (keycloak.token && org_id) {
                try {
                    const tempMembers = await getOrganizationMembers(keycloak.token, org_id)
                    const tempDetails = await getOrganizationDetails(keycloak.token, org_id)
                    setOrganization(tempDetails)
                    setOrgMembers(tempMembers.results);
                    setPageLoaded(true);
                } catch (err) {
                    console.log(err);
                }
            }
        }
        const loadCurrentUserDetails = async () => {
            if (keycloak.token) {
                const user = await getMyDetails(keycloak.token);
                setMyDetails(user);
                for (const member of orgMembers) {
                    if (Object.values(member).indexOf(user.email) >= 0) {
                        setCurrentMember(member);
                    }
                }
            }
        }
        loadOrganization();
        loadCurrentUserDetails();
    }, [keycloak.authenticated, pageLoaded]);

    const handleBackArrow = () => navigate("/organizations/")

    async function handleRemoveMember(member_id: string) {
        if (keycloak.token && org_id && member_id) {
            const tempMember = await getUserDetails(keycloak.token, org_id, member_id);
            await removeOrganizationMember(keycloak.token, org_id, member_id, tempMember);
            toast.success(`${t("Member removed from organization")}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            setRemoveMemberModal(false);
            setPageLoaded(false);
        }
    }

    const handleToggleOrgModal = () => {
        setUpdateModal(!updateModal);
        setPageLoaded(false);
    }
    const toggleOrgModalVisibility: React.MouseEventHandler<HTMLButtonElement> = () => {
        handleToggleOrgModal()
    }

    const handleToggleInviteMemberModal = () => {
        setInviteMemberModal(!inviteMemberModal);
        setPageLoaded(false);
    }

    async function handleToggleUpdateMemberModal(member_id?: string) {
        if (keycloak.token && org_id && member_id) {
            const tempMember = await getUserDetails(keycloak.token, org_id, member_id);
            setMemberToUpdate(tempMember);

        }
        setUpdateMemberModal(!updateMemberModal);
        setPageLoaded(false);
    }

    const handleToggleRemoveMemberModal = (member_id: string) => {
        setRemoveMemberModal(!removeMemberModal);
        setPageLoaded(false);
        setMemberid(member_id);
    }

    return !pageLoaded ?
        (
            <Spinner />
        ) : (
            <div>
                {updateModal &&
                    <ManageOrganizationModal
                        onToggleModal={handleToggleOrgModal}
                        existingOrg={organization}
                        newOrg={false}
                    />
                }
                {inviteMemberModal &&
                    <ManageMemberModal
                        onToggleModal={handleToggleInviteMemberModal}
                        existingMember={memberToUpdate}
                        newMember={true}
                        currentMember={currentMember}
                        superuser={myDetails.is_superuser}
                    />
                }
                {updateMemberModal &&
                    <ManageMemberModal
                        onToggleModal={handleToggleUpdateMemberModal}
                        existingMember={memberToUpdate}
                        newMember={false}
                        currentMember={currentMember}
                        superuser={myDetails.is_superuser}
                    />
                }
                {removeMemberModal &&
                    <ManageRemoveMemberModal
                        onToggleModal={handleToggleRemoveMemberModal}
                        onRemoveMember={handleRemoveMember}
                        memberid={memberid}
                        currentMember={currentMember}
                        superuser={myDetails.is_superuser}
                    />
                }
                <OrganizationDetails
                    orgMembers={orgMembers}
                    organization={organization}
                    onToggleModal={toggleOrgModalVisibility}
                    onToggleRemoveMemberModal={handleToggleRemoveMemberModal}
                    onBackArrow={handleBackArrow}
                    onClickInvite={handleToggleInviteMemberModal}
                    onClickMember={handleToggleUpdateMemberModal}
                    currentMember={currentMember}
                    superuser={myDetails.is_superuser}
                />
            </div>
        )
}