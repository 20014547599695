import { useKeycloakToken } from '../common/useKeycloak';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Spinner from '../common/Spinner';
import SourcesList from './SourcesList';
import { DestinationType, ProviderType, Source, Organization, SourceWithConnections } from '../common/ObjectTypes';
import { loadOrganizations } from "../../api/organizations/loadOrganizations";
import { getConnectionsList } from '../../api/connections/connectionsApi';
import { page_size } from '../../api/apiUtils';
import loadSources from '../../api/sources/loadSources';
import { loadProviderTypes } from '../../api/connections/loadProviderTypesFilter';
import loadDestinationUrls from '../../api/destinations/loadDestinationUrls';
import { loadDestinationTypes } from '../../api/destinations/loadDestinationTypes';
import loadConnectionsforSource from '../../api/sources/loadConnectionsforSource';
import { useKeycloak } from '@react-keycloak/web';

const SourcesPage = () => {
    type FilterTypes = {
        provider_type__in: FilterTags[],
        destination_url__in: FilterTags[],
        destination_type__in: FilterTags[],
        owner__in: FilterTags[],
        ordering: string,
        search: string,
        offset: number
    };
    function createFilterTypes(
        provider_type__in: FilterTags[],
        destination_url__in: FilterTags[],
        destination_type__in: FilterTags[],
        owner__in: FilterTags[],
        ordering: string,
        search: string,
        offset: number
    ): FilterTypes {
        return {
            provider_type__in: provider_type__in || [],
            destination_url__in: destination_url__in || [],
            destination_type__in: destination_type__in || [],
            owner__in: owner__in || [],
            ordering: ordering || "",
            search: search || "",
            offset: offset || 0
        }
    }
    type FilterTags = {
        name: string,
        id: string
    };
    const navigate = useNavigate();
    const [token, showLoginModal, setShowLoginModal] = useKeycloakToken();
    const { keycloak, initialized } = useKeycloak();
    const [sources, setSources] = useState<SourceWithConnections[]>([]);
    const [totalSources, setTotalSources] = useState<number>(0);
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [loadingOrganizations, setLoadingOrganizations] = useState(false);
    const [loadMoreOrganizations, setLoadMoreOrganizations] = useState(true);
    const [organizationOffset, setOrganizationOffset] = useState<number>(0);
    const [destinationTypes, setDestinationTypes] = useState<DestinationType[]>([]);
    const [destinationTypesOffset, setDestinationTypesOffset] = useState<number>(0);
    const [loadingDestinationTypes, setLoadingDestinationTypes] = useState(false);
    const [loadMoreDestinationTypes, setLoadMoreDestinationTypes] = useState(true);
    const [providerTypes, setProviderTypes] = useState<ProviderType[]>([]);
    const [providerTypesOffset, setProviderTypesOffset] = useState<number>(0);
    const [loadingProviderTypes, setLoadingProviderTypes] = useState(false);
    const [loadMoreProviderTypes, setLoadMoreProviderTypes] = useState(true);
    const [destinationUrls, setDestinationUrls] = useState<any[]>([]);
    const [destinationUrlOffset, setDestinationUrlOffset] = useState<number>(0);
    const [loadingDestinationUrls, setLoadingDestinationUrls] = useState(false);
    const [loadMoreDestinationUrls, setLoadMoreDestinationUrls] = useState(true);
    const [reloadSources, setReloadSources] = useState(true);
    const [reloadConnections, setReloadConnections] = useState(true);
    const [pageLoaded, setPageLoaded] = useState(false);
    const [sourceFilter, setSourceFilter] = useState<FilterTypes>(() => {
        const savedFilter = localStorage.getItem('sourceFilter');
        return savedFilter ? JSON.parse(savedFilter) : createFilterTypes([], [], [], [], "-external_id", "", 0);
    });
    const [connectionsFilter, setConnectionsFilter] = useState<FilterTypes>(createFilterTypes([], [], [], [], "", "name", 0));
    const [offset, setOffset] = useState<number>(0);
    const [destDetailsModal, setDestDetailsModal] = useState(false);

    const loadSourcesData = async (sourceFilter: FilterTypes) => {
        if (token && typeof token === 'string' && !keycloak.isTokenExpired()) {
            const [source_results, total_sources, error] = await loadSources(token, "", sourceFilter);
            if (!error) {

                const sources_with_connections: SourceWithConnections[] = [];
                for (const source of source_results) {
                    // const result = await loadConnectionsforSource(token, source);
                    sources_with_connections.push({ source: source, connections: [] });
                }
                setSources(sources_with_connections);
                setTotalSources(total_sources);
                setReloadSources(false);
                setPageLoaded(true);
            }
        }
    }

    const loadProviderTypesData = async (offset: number, search_str: string) => {
        if (token && typeof token === 'string' && !keycloak.isTokenExpired()) {
            const [provider_types, total_provider_types, error] = await loadProviderTypes(token, offset, search_str, destinationTypes);
            setLoadingProviderTypes(true);
            if (!error) {
                setProviderTypes(provider_types);
                if (offset + page_size >= total_provider_types) {
                    setLoadMoreProviderTypes(false);
                }
                setLoadingProviderTypes(false);
            }
        }
    }

    const loadDestinationURlsData = async (offset: number, search_str: string) => {
        if (token && typeof token === 'string' && !keycloak.isTokenExpired()) {
            const [dest_urls, total_urls, error] = await loadDestinationUrls(token, offset, search_str, destinationUrls);
            setLoadingDestinationUrls(true);
            if (!error) {
                setDestinationUrls([...new Set([...destinationUrls, ...dest_urls])]);
                if (offset + page_size >= total_urls) {
                    setLoadMoreDestinationUrls(false);
                }
                setLoadingDestinationUrls(false);
            }
        }
    }

    const loadDestinationTypesData = async (offset: number, search_str: string) => {
        if (token && typeof token === 'string' && !keycloak.isTokenExpired()) {
            const [dest_types, total_dest_types, error] = await loadDestinationTypes(token, offset, search_str, destinationTypes);
            setLoadingDestinationTypes(true);
            if (!error) {
                setDestinationTypes(dest_types);
                if (offset + page_size >= total_dest_types) {
                    setLoadMoreDestinationTypes(false);
                }
                setLoadingDestinationTypes(false);
            }
        }
    }

    const loadOrganizationsData = async (offset: number, search_str: string) => {
        if (token && typeof token === 'string' && !keycloak.isTokenExpired()) {
            const [orgs, total_orgs, error] = await loadOrganizations(token, offset, search_str, organizations);
            setLoadingOrganizations(true);
            if (!error) {
                setOrganizations(orgs);
                if (offset + page_size >= total_orgs) {
                    setLoadMoreOrganizations(false);
                }
                setLoadingOrganizations(false);
            }
        }
    }

    useEffect(() => {
        const loadConnections = async () => {
            if (token && typeof token === 'string' && !keycloak.isTokenExpired()) {
                try {
                    const connectionFilterQuery = {
                        provider_type__in: connectionsFilter.provider_type__in.map(a => a.id).toString(),
                        destination_type__in: connectionsFilter.destination_type__in.map(a => a.id).toString(),
                        destination_url__in: connectionsFilter.destination_url__in.map(a => a.id).toString(),
                        owner__in: connectionsFilter.owner__in.map(a => a.id).toString(),
                        ordering: connectionsFilter.ordering,
                        search: connectionsFilter.search,
                        offset: offset,
                        previous: process.env.REACT_APP_BASE_URL_LOCAL + "/connections/?pager=limit&limit=" + page_size + "&offset=" + (offset - page_size),
                        next: process.env.REACT_APP_BASE_URL_LOCAL + "/connections/?pager=limit&limit=" + page_size + "&offset=" + (offset + page_size)
                    }
                    const temp = await getConnectionsList(token, connectionFilterQuery)
                    setReloadConnections(false);

                } catch (err) {
                    console.log(err)
                }
            }
        }
        if (reloadConnections) loadConnections();
        loadDestinationURlsData(destinationUrlOffset, "");
        loadProviderTypesData(providerTypesOffset, "");
        loadDestinationTypesData(destinationTypesOffset, "");
        loadOrganizationsData(organizationOffset, "");

    }, [token])

    useEffect(() => {
        localStorage.setItem('sourceFilter', JSON.stringify(sourceFilter));
        if (reloadSources) loadSourcesData(sourceFilter);
    }, [token, reloadSources, sourceFilter])

    const handlePageClick = (e: any) => {
        setSourceFilter((prevFilter: any) => ({
            ...prevFilter,
            offset: e.selected * page_size
        }))
        setReloadSources(true);
    }

    const handleGlobalSearch = (searchSubStr: string) => {
        setSourceFilter((prevFilter: any) => ({
            ...prevFilter,
            search: searchSubStr,
            offset: 0,
        }));
        setReloadSources(true);
    }

    const handleFilterOwner = (e: any) => {
        let tempArray: FilterTags[] = sourceFilter.owner__in
        if (tempArray.map(a => a.id).includes(e.target.value)) {
            let removed: FilterTags[] = []
            for (var item in tempArray) {
                if (tempArray[item].id !== e.target.value) removed.push(tempArray[item])
            }
            setSourceFilter((prevFilter: any) => ({
                ...prevFilter,
                [e.target.id]: removed,
                offset: 0,
            }))
        } else {
            let newTag = {
                name: e.target.name,
                id: e.target.value
            }
            tempArray.push(newTag)
            setSourceFilter((prevFilter: any) => ({
                ...prevFilter,
                [e.target.id]: tempArray,
                offset: 0,
            }))
        }
        setReloadSources(true);
    }

    const handleFilterDestinationType = (e: any) => {
        let tempArray: FilterTags[] = sourceFilter.destination_type__in
        if (tempArray.map(a => a.id).includes(e.target.value)) {
            let removed: FilterTags[] = []
            for (var item in tempArray) {
                if (tempArray[item].id !== e.target.value) {
                    removed.push(tempArray[item])
                }

            }
            setSourceFilter((prevFilter: any) => ({
                ...prevFilter,
                [e.target.id]: removed,
                offset: 0,
            }))
        } else {
            let newTag = {
                name: e.target.name,
                id: e.target.value
            }
            tempArray.push(newTag)
            setSourceFilter((prevFilter: any) => ({
                ...prevFilter,
                [e.target.id]: tempArray,
                offset: 0,
            }))
        }
        setReloadSources(true);
    }

    const handleChangeDestinationUrlSearchBar = (e: any) => {
        if (e.target.value.length >= 3 || e.target.value.length === 0) {
            loadDestinationURlsData(destinationUrlOffset, e.target.value);
        }
    }

    const handleLoadMoreDestinationUrls = () => {
        setDestinationUrlOffset(destinationUrlOffset + page_size);
        loadDestinationURlsData(destinationUrlOffset + page_size, "");
    }

    const handleFilterEndpoint = (e: any) => {
        let tempArray: FilterTags[] = sourceFilter.destination_url__in
        if (tempArray.map(a => a.id).includes(e.target.value)) {
            let removed: FilterTags[] = []
            for (var item in tempArray) {
                if (tempArray[item].id !== e.target.value) removed.push(tempArray[item])
            }
            setSourceFilter((prevFilter: any) => ({
                ...prevFilter,
                [e.target.id]: removed,
                offset: 0,
            }))
        } else {
            let newTag = {
                name: e.target.name,
                id: e.target.value
            }
            tempArray.push(newTag)
            setSourceFilter((prevFilter: any) => ({
                ...prevFilter,
                [e.target.id]: tempArray,
                offset: 0,
            }))
        }
        setReloadSources(true);
    }

    const handleChangeProviderTypeSearchBar = (e: any) => {
        if (e.target.value.length >= 3 || e.target.value.length === 0) {
            loadProviderTypesData(providerTypesOffset, e.target.value);
        }
    }

    const handleLoadMoreProviderTypes = () => {
        setProviderTypesOffset(providerTypesOffset + page_size);
        loadProviderTypesData(providerTypesOffset + page_size, "");
    }

    const handleFilterProviderType = (e: any) => {
        let tempArray: FilterTags[] = sourceFilter.provider_type__in
        if (tempArray.map(a => a.id).includes(e.target.value)) {
            let removed: FilterTags[] = []
            for (var item in tempArray) {
                if (tempArray[item].id !== e.target.value) {
                    removed.push(tempArray[item])
                }

            }
            setSourceFilter((prevFilter: any) => ({
                ...prevFilter,
                [e.target.id]: removed,
                offset: 0,
            }))
        } else {
            let newTag = {
                name: e.target.name,
                id: e.target.value
            }
            tempArray.push(newTag)
            setSourceFilter((prevFilter: any) => ({
                ...prevFilter,
                [e.target.id]: tempArray,
                offset: 0,
            }))
        }
        setReloadSources(true);
    }

    const handleChangeOrganizationSearchBar = (e: any) => {
        if (e.target.value.length >= 3 || e.target.value.length === 0) {
            loadOrganizationsData(organizationOffset, e.target.value);
        }
    }

    const handleLoadMoreOrganizations = () => {
        setOrganizationOffset(organizationOffset + page_size);
        loadOrganizationsData(organizationOffset + page_size, "");
    }

    const handleChangeDestinationTypeSearchBar = (e: any) => {
        if (e.target.value.length >= 3 || e.target.value.length === 0) {
            loadDestinationTypesData(destinationTypesOffset, e.target.value);
        }
    }

    const handleLoadMoreDestinationTypes = () => {
        setDestinationTypesOffset(destinationTypesOffset + page_size);
        loadDestinationTypesData(destinationTypesOffset + page_size, "");
    }

    const handleResetAllFilters = () => {
        setSourceFilter({
            provider_type__in: [],
            destination_url__in: [],
            destination_type__in: [],
            owner__in: [],
            ordering: "",
            search: "",
            offset: 0
        });
        setReloadSources(true);
    }

    const handleRemoveTag = (e: any) => {
        if (sourceFilter.owner__in.map(a => a.id).includes(e)) {
            let tempArray: FilterTags[] = sourceFilter.owner__in
            let removed: FilterTags[] = []
            for (var item in tempArray) {
                if (tempArray[item].id !== e) removed.push(tempArray[item])
            }
            setSourceFilter((prevFilter: any) => ({
                ...prevFilter,
                owner__in: removed,
                offset: 0,
            }))
        } else if (sourceFilter.destination_type__in.map(a => a.id).includes(e)) {
            let tempArray: FilterTags[] = sourceFilter.destination_type__in
            let removed: FilterTags[] = []
            for (item in tempArray) {
                if (tempArray[item].id !== e) removed.push(tempArray[item])
            }
            setSourceFilter((prevFilter: any) => ({
                ...prevFilter,
                destination_type__in: removed,
                offset: 0,
            }))
        } else if ((sourceFilter.destination_url__in.map(a => a.id).includes(e))) {
            let tempArray: FilterTags[] = sourceFilter.destination_url__in
            let removed: FilterTags[] = []
            for (item in tempArray) {
                if (tempArray[item].id !== e) removed.push(tempArray[item])
            }
            setSourceFilter((prevFilter: any) => ({
                ...prevFilter,
                destination_url__in: removed,
                offset: 0,
            }))
        } else if (sourceFilter.provider_type__in.map(a => a.id).includes(e)) {
            let tempArray: FilterTags[] = sourceFilter.provider_type__in
            let removed: FilterTags[] = []
            for (item in tempArray) {
                if (tempArray[item].id !== e) removed.push(tempArray[item])
            }
            setSourceFilter((prevFilter: any) => ({
                ...prevFilter,
                provider_type__in: removed,
                offset: 0,
            }))
        }
        setReloadSources(true);
    }

    const handleSortTable = (orderBy: string, sortDirection: boolean) => {
        console.log(orderBy, sortDirection)
        const ordering = sortDirection ? orderBy : "-" + orderBy;
        setSourceFilter((prevFilter: any) => ({
            ...prevFilter,
            ordering: ordering
        }))
        setReloadSources(true);
    }

    const handleToggleDestinationDetailsModal = async (dest_id: string) => {
        if (dest_id && token && typeof token === 'string' && !keycloak.isTokenExpired()) {
            // Append dest_id to the current URL
            navigate(`/sources/destination/${dest_id}/details`, { replace: false });
        }
        setDestDetailsModal(!destDetailsModal);
        if (destDetailsModal) setReloadConnections(true);
    }

    return !pageLoaded ?
        (
            <Spinner />
        ) : (
            <div>
                <SourcesList
                    sources={sources}
                    totalCount={totalSources}
                    take={page_size}
                    offset={sourceFilter.offset}
                    onPageClicked={handlePageClick}
                    loading={reloadSources}
                    organizations={organizations}
                    providerTypes={providerTypes}
                    destinationTypes={destinationTypes}
                    destinationUrls={destinationUrls}
                    filters={sourceFilter.provider_type__in
                        .concat(sourceFilter.destination_url__in, sourceFilter.destination_type__in, sourceFilter.owner__in)}
                    globalSearchStr={sourceFilter.search}
                    requestSort={handleSortTable}
                    onViewDetails={handleToggleDestinationDetailsModal}
                    onChangeGlobalSearch={handleGlobalSearch}
                    onChangeOwner={handleFilterOwner}
                    onChangeOwnerSearchBar={handleChangeOrganizationSearchBar}
                    loadingOrganizations={loadingOrganizations}
                    loadMoreOrganizations={loadMoreOrganizations}
                    onLoadMoreOrganizations={handleLoadMoreOrganizations}
                    onChangeEndpoint={handleFilterEndpoint}
                    loadingDestinationUrls={loadingDestinationUrls}
                    loadMoreDestinationUrls={loadMoreDestinationUrls}
                    onLoadMoreDestinationUrls={handleLoadMoreDestinationUrls}
                    onChangeDestinationUrlSearchBar={handleChangeDestinationUrlSearchBar}
                    onChangeDestinationType={handleFilterDestinationType}
                    onChangeDestinationTypeSearchBar={handleChangeDestinationTypeSearchBar}
                    onLoadMoreDestinationTypes={handleLoadMoreDestinationTypes}
                    loadingDestinationTypes={loadingDestinationTypes}
                    loadMoreDestinationTypes={loadMoreDestinationTypes}
                    onChangeProviderTypeSearchBar={handleChangeProviderTypeSearchBar}
                    onChangeProviderType={handleFilterProviderType}
                    loadingProviderTypes={loadingProviderTypes}
                    loadMoreProviderTypes={loadMoreProviderTypes}
                    onLoadMoreProviderTypes={handleLoadMoreProviderTypes}
                    onResetFilters={handleResetAllFilters}
                    onRemoveTag={handleRemoveTag}
                />
            </div>
        )
};

export default SourcesPage;