import React, { useState, useEffect, ChangeEvent } from "react";
import { useKeycloak } from '@react-keycloak/web';
import Spinner from "../common/Spinner";
import { loadOrganizations } from "../../api/organizations/loadOrganizations";
import DestinationModal from "./DestinationModal";
import { addDestination, getDestinationTypes } from "../../api/destinations/destinationsApi";
import { toast } from 'react-toastify';
import { NewIntegration, DestinationType, Organization, buildNewIntegration, buildDestinationType } from "../common/ObjectTypes";
import Ajv from 'ajv';
import addFormats from 'ajv-formats';
import { page_size } from "../../api/apiUtils";
import { useTranslation } from "react-i18next";

type ManageDestinationModalProps = {
    onToggleModal: Function
}

export function ManageDestinationModal({ onToggleModal }: ManageDestinationModalProps) {
    const { keycloak } = useKeycloak();
    const { t } = useTranslation();
    const [pageLoaded, setPageLoaded] = useState(true);
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [totalOrgs, setTotalOrgs] = useState<number>(0);
    const [orgSearchInput, setOrgSearchInput] = useState<string>("");
    const [offset, setOffset] = useState<number>(0);
    const [reloadOrgs, setReloadOrgs] = useState<boolean>(true);
    const [destinationTypes, setDestinationTypes] = useState<DestinationType[]>([]);
    const [destination, setDestination] = useState<NewIntegration>(buildNewIntegration(false))
    const [updatedConfigurations, setUpdatedConfigurations] = useState<any[]>([]);
    const [destinationType, setDestinationType] = useState<DestinationType>(buildDestinationType());
    const [stepOne, setStepOne] = useState(true);
    const [stepTwo, setStepTwo] = useState(false);
    const [errors, setErrors] = useState({});
    const [rsjfErrors, setRsjfErrors] = useState<any[]>([]);
    const toggleModalMouseEvent: React.MouseEventHandler<HTMLButtonElement> = () => onToggleModal(); // function passed from parent needs to be a mouse event


    useEffect(() => {
        const loadOrganizationsData = async () => {
            if (keycloak.token) {
                const [orgs, total_orgs, error] = await loadOrganizations(keycloak.token, offset, orgSearchInput, organizations);
                if (!error) {
                    setOrganizations(orgs);
                    if (orgSearchInput.length === 0) setTotalOrgs(total_orgs);
                    setReloadOrgs(false);
                }
            }
        }
        const loadDestinationTypes = async () => {
            if (keycloak.token) {
                try {
                    const temp = await getDestinationTypes(keycloak.token)
                    setDestinationTypes(temp.results);
                } catch (err) {
                    console.log(err)
                }
            }
        }
        loadDestinationTypes();
        if (reloadOrgs) loadOrganizationsData();
    }, [keycloak.authenticated, reloadOrgs])

    function handleSelectOrg(event: any) {
        setDestination((prevDest: NewIntegration) => ({
            ...prevDest,
            "owner": event.value,
        }))
    }
    const handleSelectType = (event: any) => {
        setDestinationType({
            id: event.id,
            name: event.name,
            description: event.description,
            value: event.value,
            actions: event.actions
        })
        setDestination((prevDest: NewIntegration) => ({
            ...prevDest,
            "type": event.value,
        }))
    }

    function handleStepTwo() {
        setStepOne(false);
        setStepTwo(true);
    }

    const formIsValid = () => {
        let form_errors: any = {};
        let rsjf_errors: any = {};
        if (destination.name.length === 0) form_errors.name = "Name is required.";
        if (destination.base_url.length === 0) {
            form_errors.base_url = "URL is required";
        }
        if (destination.base_url.length > 0) {
            try {
                new URL(destination.base_url);
            } catch (err) {
                form_errors.base_url = "Enter a valid url including the scheme.";
            }
        }
        if (destination.owner === "") form_errors.owner = "Select an Organization."
        setErrors(form_errors);

        updatedConfigurations.forEach((config: any, index: number) => {
            const schema_index = destinationType.actions.findIndex((a: any) => a.id === config.action);
            const ajv = new Ajv({ allErrors: true, verbose: true });
            ajv.addKeyword({
                keyword: 'is_executable',
                validate: function () {
                    return true;
                },
                errors: false
            });
            ajv.addKeyword({
                keyword: 'example',
                validate: function () {
                    return true;
                },
                errors: false
            });
            addFormats(ajv);
            const schema = destinationType.actions[schema_index].schema;
            const validate = ajv.compile(schema);
            const data = { ...config.data };

            if (schema.properties && typeof schema.properties === 'object') {
                Object.keys(schema.properties).forEach((key) => {
                    if (!data.hasOwnProperty(key)) {
                        data[key] = null;
                    } else {
                        if (data[key] === undefined) {
                            data[key] = null; // or any default value you prefer
                        }
                    }
                });
            } else {
                console.error('schema.properties is not defined or is not an object');
            }

            const valid = validate(data);
            if (!valid) {
                validate.errors?.forEach((error: any) => {
                    console.error(`Error in field '${error.instancePath}': ${error.message}`);
                    const field = error.instancePath.slice(1);
                    if (!rsjf_errors[destinationType.actions[schema_index].id]) {
                        rsjf_errors[destinationType.actions[schema_index].id] = {};
                    }
                    rsjf_errors[destinationType.actions[schema_index].id][field] = `Error in field '${field}': ${error.message}`;
                });
            }
        });

        setRsjfErrors(rsjf_errors);

        return Object.keys(form_errors).length === 0 && Object.keys(rsjf_errors).length === 0;
    }

    async function handleAddDestination() {
        if (!formIsValid()) return;
        else {
            if (keycloak.token) {
                const temp = await addDestination(keycloak.token, destination);
                toast.success(`${t("Destination added")}`, {
                    position: toast.POSITION.TOP_RIGHT
                });
                onToggleModal(temp.id);
            }
        }
    }

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        const item = event.target
        const item_id: string = item.id
        setDestination((prevDest: NewIntegration) => ({
            ...prevDest,
            [item_id]: item.value,
        }))
    }

    function handleChangeJSONForm(e: ChangeEvent<HTMLInputElement>, action_id: string) {
        const newConfig = {
            "action": action_id,
            "data": e
        }

        if (e) {
            const index_to_find = updatedConfigurations.findIndex((a: any) => a.action === action_id)
            if (index_to_find === -1) {

                // if this is a new config id, push it to the array
                setUpdatedConfigurations((prevArray: any[]) => [...prevArray, newConfig])
            } else {

                // if it is an existing config id, replace the old config with the new one
                const tempArray = updatedConfigurations
                tempArray[index_to_find] = newConfig
                setUpdatedConfigurations(tempArray)
            }
        }
        setDestination((prevConnection: any) => ({
            ...prevConnection,
            configurations: updatedConfigurations
        }))
    }

    function handleSearchOrgDropdown(e: any) {
        setOrgSearchInput(e);
        if (e.length > 2) setReloadOrgs(true);
    }

    function handleLoadMoreOrgs() {
        setOffset(offset + page_size)
        if (organizations.length < totalOrgs) setReloadOrgs(true);
    }

    return !pageLoaded ? (
        <Spinner />
    ) : (

        <DestinationModal
            errors={errors}
            rsjfErrors={rsjfErrors}
            onToggleModal={toggleModalMouseEvent}
            onNextStep={handleStepTwo}
            stepOne={stepOne}
            stepTwo={stepTwo}
            destination={destination}
            destinationType={destinationType}
            organizations={organizations}
            loadMoreOrgs={handleLoadMoreOrgs}
            orgInputChange={handleSearchOrgDropdown}
            destinationTypes={destinationTypes}
            onChange={handleChange}
            onChangeOrg={handleSelectOrg}
            onChangeType={handleSelectType}
            onChangeJSONForm={handleChangeJSONForm}
            onSave={handleAddDestination}
        />
    )
}