import React, { MouseEventHandler, useState } from "react";
import { useTranslation } from "react-i18next";
import { Connection, Destination, Organization } from "../common/ObjectTypes";
import DestinationDetailsModalTable from "./DestinationDetailsModalTable";
import DestinationDetailsModalConfig from "./DestinationDetailsModalConfig";
import Spinner from "../common/Spinner";

type DestinationDetailsProps = {
    destination: Destination,
    destinationTitle: string,
    destSubPage: string | undefined,
    onSelectSubPage: Function,
    connections: Connection[],
    organizations: Organization[],
    loadMoreOrgs: Function,
    orgInputChange: Function,
    onToggleModal: MouseEventHandler,
    requestSort: Function,
    loadingConnections: boolean,
    onChangeSelectedDestination: Function,
    onChangeDestinationEnabled: Function,
    onChangeSelectedDestinationConfig: Function,
    onTestAction: Function,
    testActionLoading: boolean,
    testActionResponse: any,
    testActionStatus: number,
    onChangeSelectedDestinationOwner: Function,
    onSave: MouseEventHandler,
    errors: any,
    rsjfErrors: any,
    onPageClicked: Function,
    totalCount: number,
    pageLoaded: boolean,
    take: number,
    offset: number
}

const DestinationDetailsModal = ({
    destination,
    destinationTitle,
    destSubPage,
    onSelectSubPage,
    connections,
    organizations,
    loadMoreOrgs,
    orgInputChange,
    onToggleModal,
    requestSort,
    loadingConnections,
    onChangeSelectedDestination,
    onChangeDestinationEnabled,
    onChangeSelectedDestinationConfig,
    onTestAction,
    testActionLoading,
    testActionResponse,
    testActionStatus,
    onChangeSelectedDestinationOwner,
    onSave,
    errors,
    rsjfErrors,
    onPageClicked,
    totalCount,
    pageLoaded,
    take,
    offset
}: DestinationDetailsProps) => {
    const { t } = useTranslation();

    return (
        <div className="absolute left-0 z-30">
            <div className="fixed top-0 left-0 right-0 h-screen bg-dark-gray opacity-80"
                onClick={onToggleModal}></div>
            <div className="relative flex justify-center w-screen h-screen opacity-100">
                <div className="z-20 flex flex-col w-1/2 h-3/4 shadow rounded-lg bg-white">
                    {/* top portion */}
                    <div className="bg-white z-30 sticky top-0 flex flex-col rounded-lg">
                        <div className="flex flex-row-reverse">
                            <button
                                type="button"
                                onClick={onToggleModal}
                                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-3 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                                data-modal-hide="authentication-modal"
                            >
                                <svg aria-hidden="true" className="w-7 h-7 text-off-black" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                <span className="sr-only">{t("Close Modal")}</span>
                            </button>
                            <label className="p-4 text-medium-gray font-semibold">{t("Destination")}</label>
                        </div>
                        <div className="flex flex-col my-1 px-4 w-full shadow-md bg-white">
                            {/* destination name */}
                            <h1 className="text-4xl font-semibold">{destinationTitle}</h1>
                            {/* menu tabs */}
                            <div className={pageLoaded ? "w-full flex flex-row font-semibold text-medium-gray visible" : "invisible"}>
                                <label className={(destSubPage === "details") ? "border-2 border-white border-b-status-green text-dark-gray mr-3 py-3 cursor-pointer" : "border-2 border-white mr-3 py-3 cursor-pointer"}
                                    onClick={() => onSelectSubPage("details")}>
                                    {t("Connections")}
                                </label>
                                <label className={(destSubPage === "configuration") ? "border-2 border-white border-b-status-green text-dark-gray mr-3 py-3 cursor-pointer" : "border-2 border-white mr-3 py-3 cursor-pointer"}
                                    onClick={() => onSelectSubPage("configuration")}>
                                    {t("Configuration")}
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* scrollable content */}

                    <div className="flex flex-col overflow-y-scroll h-full w-full rounded-lg">
                        {!pageLoaded ?
                            <div className="flex justify-center items-center h-full">
                                <Spinner />
                            </div>
                            :
                            <div className="pt-3 px-4 h-full bg-white">
                                {(destSubPage === "details") && <DestinationDetailsModalTable
                                    connections={connections}
                                    requestSort={requestSort}
                                    loadingConnections={loadingConnections}
                                    onPageClicked={onPageClicked}
                                    totalCount={totalCount}
                                    pageLoaded={pageLoaded}
                                    take={take}
                                    offset={offset}
                                />}
                                {(destSubPage === "configuration") && <DestinationDetailsModalConfig
                                    destination={destination}
                                    organizations={organizations}
                                    loadMoreOrgs={loadMoreOrgs}
                                    orgInputChange={orgInputChange}
                                    onChange={onChangeSelectedDestination}
                                    onChangeSelectedDestinationOwner={onChangeSelectedDestinationOwner}
                                    onChangeDestinationEnabled={onChangeDestinationEnabled}
                                    onChangeSelectedDestinationConfig={onChangeSelectedDestinationConfig}
                                    onTestAction={onTestAction}
                                    testActionLoading={testActionLoading}
                                    testActionResponse={testActionResponse}
                                    testActionStatus={testActionStatus}
                                    onSave={onSave}
                                    onToggleModal={onToggleModal}
                                    errors={errors}
                                    rsjfErrors={rsjfErrors}
                                />}
                            </div>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DestinationDetailsModal;