import React, { useState, useEffect, useCallback } from "react";
import validator from '@rjsf/validator-ajv8';
import Form from '@rjsf/core';
import { RJSFSchema, UiSchema, WidgetProps } from '@rjsf/utils';
import { generateUISchema } from "./SchemaFormTemplates/generateUISchema";
import { useTranslation } from "react-i18next";

// Define the types for SchemaFormProps
type SchemaFormProps = {
  formTitle: string,
  schema: RJSFSchema,
  ui_schema: UiSchema,
  formdata?: Object,
  onChange: Function
  isEditable: boolean,
  errors?: any
}

const SchemaForm = ({
  formTitle,
  schema,
  ui_schema,
  formdata,
  onChange,
  isEditable,
  errors
}: SchemaFormProps) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [customSchema, setCustomSchema] = useState<UiSchema>({});
  const [formData, setFormData] = useState(formdata ? formdata : null);
  const [loading, setLoading] = useState(true); // Add loading state
  const printError = (error: any) => console.error("schema form error", { error });
  const togglePassword = useCallback(() => {
    setShowPassword((prev) => !prev);
  }, []);

  useEffect(() => {
    setCustomSchema(generateUISchema(schema, ui_schema, errors, showPassword, togglePassword, formData));
  }, [schema, ui_schema, errors, JSON.stringify(formData)]);

  useEffect(() => {
    setFormData(formdata || {});
  }, []);

  // Add this useEffect to listen for changes in formData
  useEffect(() => {
    if (formData) {
      setCustomSchema(generateUISchema(schema, ui_schema, errors, showPassword, togglePassword, formData));
    }
  }, [JSON.stringify(formData), showPassword]);

  const handleFormChange = (newFormData: any) => {
    setFormData(newFormData); // Update form data without regenerating UI schema on every change
  };

  return (
    <div className="flex flex-col" id="json-form">
      <div className="text-lg font-semibold text-secondary-gray">{formTitle}</div>
      <div className="custom-form">
        <Form
          key={JSON.stringify(customSchema)}
          schema={schema}
          uiSchema={customSchema}
          validator={validator}
          onChange={(e) => {
            handleFormChange(e.formData)
            onChange(e.formData)
          }}
          onError={printError}
          children={true}
          formData={formData}
          disabled={!isEditable}
        />
      </div>
    </div>
  )
}

export default SchemaForm;
