import React from 'react';
import { Tooltip } from '../Tooltip';

interface CustomCheckboxProps {
    schema?: any;
    value: any;
    label: string;
    checked: boolean;
    itemsData: any;
    enumItem: any;
    onChange: (updatedItems: boolean | any[]) => void;
    formData: any;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
    schema,
    value,
    label,
    checked,
    itemsData,
    enumItem,
    onChange,
    formData
}) => {
    const isChecked = Array.isArray(formData)
        ? formData.includes(enumItem) // Check if the current item is in the array
        : formData ?? value ?? checked ?? false;
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target;

        const updatedItems = Array.isArray(formData)
            ? (checked
                ? [...formData, enumItem] // Add to array if checked
                : formData.filter((item: any) => item !== enumItem)) // Remove if unchecked
            : checked; // Otherwise, just store the boolean value

        onChange(updatedItems);
    };
    const id = `custom-checkbox-${label}`;
    return (
        <div className='visible flex flex-row items-baseline'>
            <input
                type="checkbox"
                id={id}
                className="accent-dark-green cursor-pointer"
                checked={isChecked}
                onChange={handleChange}
            />
            <label htmlFor={id} key={value} className="visible flex flex-row ml-2 mr-1 text-secondary-gray">
                {schema ? schema.title : label}
            </label>
            <label key={Math.random()} className="flex flex-row text-secondary-gray">
                {schema && schema.description && <Tooltip position="right" description={schema.description} />}
            </label>
        </div>
    );
};

export default CustomCheckbox;