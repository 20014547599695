import { useKeycloakToken } from '../common/useKeycloak';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDestinationsList } from '../../api/destinations/destinationsApi';
import DestinationsList from './DestinationsList';
import Spinner from "../common/Spinner";
import { ManageDestinationModal } from './ManageDestinationModal';
import { Destination, DestinationType, Organization, buildDestination } from '../common/ObjectTypes';
import { ManageDestinationDetails } from './ManageDestinationDetails';
import { loadOrganizations } from "../../api/organizations/loadOrganizations";
import { page_size } from '../../api/apiUtils';
import loadDestinationUrls from '../../api/destinations/loadDestinationUrls';
import { loadDestinationTypes } from '../../api/destinations/loadDestinationTypes';
import getUserPermissions from '../../api/users/getUserPermissions';
import { useKeycloak } from '@react-keycloak/web';

const DestinationsPage = () => {
    type FilterTypes = {
        base_url__in: FilterTags[],
        enabled: FilterTags[],
        type__in: FilterTags[],
        owner__in: FilterTags[],
        ordering: string,
        search: string
    };
    type FilterTags = {
        name: string,
        id: string
    };
    const defaultDestinationsFilter = {
        base_url__in: [],
        enabled: [],
        type__in: [],
        owner__in: [],
        ordering: "name",
        search: ""
    }
    const navigate = useNavigate();
    const [token, showLoginModal, setShowLoginModal] = useKeycloakToken();
    const { keycloak, initialized } = useKeycloak();
    const [canAddDestination, setCanAddDestination] = useState(false);
    const [destinations, setDestinations] = useState<Destination[]>([]);
    const [destinationFilter, setDestinationFilter] = useState<FilterTypes>(() => {
        const savedFilter = localStorage.getItem('destinationFilter');
        return savedFilter ? JSON.parse(savedFilter) : defaultDestinationsFilter
    });
    const [totalCount, setTotalCount] = useState<number>(0);
    const [offset, setOffset] = useState<number>(0);
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [organizationOffset, setOrganizationOffset] = useState<number>(0);
    const [loadingOrganizations, setLoadingOrganizations] = useState(false);
    const [loadMoreOrganizations, setLoadMoreOrganizations] = useState(true);
    const [destinationTypes, setDestinationTypes] = useState<DestinationType[]>([]);
    const [destinationTypesOffset, setDestinationTypesOffset] = useState<number>(0);
    const [loadingDestinationTypes, setLoadingDestinationTypes] = useState(false);
    const [loadMoreDestinationTypes, setLoadMoreDestinationTypes] = useState(true);
    const [destinationUrls, setDestinationUrls] = useState<any[]>([]);
    const [destinationUrlOffset, setDestinationUrlOffset] = useState<number>(0);
    const [loadingDestinationUrls, setLoadingDestinationUrls] = useState(false);
    const [loadMoreDestinationUrls, setLoadMoreDestinationUrls] = useState(true);
    const [pageLoaded, setPageLoaded] = useState(false);
    const [reloadDestinations, setReloadDestinations] = useState(true);
    const [addDestModal, setAddDestModal] = useState(false);
    const [destDetailsModal, setDestDetailsModal] = useState(false);

    const loadDestinationURlsData = async (offset: number, search_str: string) => {
        if (token && typeof token === 'string' && !keycloak.isTokenExpired()) {
            const [dest_urls, total_urls, error] = await loadDestinationUrls(token, offset, search_str, destinationUrls);
            setLoadingDestinationUrls(true);
            if (!error) {
                setDestinationUrls([...new Set([...destinationUrls, ...dest_urls])]);
                if (offset + page_size >= total_urls) {
                    setLoadMoreDestinationUrls(false);
                }
                setLoadingDestinationUrls(false);
            }
        }
    }

    const loadDestinationTypesData = async (offset: number, search_str: string) => {
        if (token && typeof token === 'string' && !keycloak.isTokenExpired()) {
            const [dest_types, total_dest_types, error] = await loadDestinationTypes(token, offset, search_str, destinationTypes);
            setLoadingDestinationTypes(true);
            if (!error) {
                setDestinationTypes(dest_types);
                if (offset + page_size >= total_dest_types) {
                    setLoadMoreDestinationTypes(false);
                }
                setLoadingDestinationTypes(false);
            }
        }
    }

    const loadOrganizationsData = async (offset: number, search_str: string) => {
        if (token && typeof token === 'string' && !keycloak.isTokenExpired()) {
            const [orgs, total_orgs, error] = await loadOrganizations(token, offset, search_str, organizations);
            setLoadingOrganizations(true);
            if (!error) {
                setOrganizations(orgs);
                if (offset + page_size >= total_orgs) {
                    setLoadMoreOrganizations(false);
                }
                setLoadingOrganizations(false);
            }
        }
    }

    useEffect(() => {
        localStorage.setItem('destinationFilter', JSON.stringify(destinationFilter));
        const loadPerms = async () => {
            if (token && typeof token === 'string' && !keycloak.isTokenExpired()) {
                try {
                    const temp = await getUserPermissions(token);
                    setCanAddDestination(temp);
                } catch (err) {
                    console.log(err)
                }
            }
        }

        const loadDestinations = async () => {
            if (token && typeof token === 'string' && !keycloak.isTokenExpired()) {
                try {
                    const destinationFilterQuery = {
                        base_url__in: destinationFilter.base_url__in.map(a => a.id).toString(),
                        enabled: destinationFilter.enabled.map(a => a.id).toString(),
                        type__in: destinationFilter.type__in.map(a => a.id).toString(),
                        owner__in: destinationFilter.owner__in.map(a => a.id).toString(),
                        ordering: destinationFilter.ordering,
                        search: destinationFilter.search,
                        offset: offset,
                        previous: process.env.REACT_APP_BASE_URL_LOCAL + "/integrations/?pager=limit&limit=" + page_size + "&offset=" + (offset - page_size),
                        next: process.env.REACT_APP_BASE_URL_LOCAL + "/integrations/?pager=limit&limit=" + page_size + "&offset=" + (offset + page_size)
                    }
                    const temp = await getDestinationsList(token, destinationFilterQuery);
                    setDestinations(temp.results);
                    setTotalCount(temp.count);
                    setPageLoaded(true);
                    setReloadDestinations(false);
                } catch (err) {
                    console.log(err)
                }
            }
        }

        if (reloadDestinations) loadDestinations();
        if (!pageLoaded) {
            loadPerms();
            loadDestinationTypesData(destinationTypesOffset, "");
            loadOrganizationsData(organizationOffset, "");
            loadDestinationURlsData(destinationUrlOffset, "");
        }
    }, [token, reloadDestinations, destinationFilter])

    const handleToggleAddDestinationModal = (openDest_id?: string) => {
        setAddDestModal(!addDestModal);
        if (openDest_id) handleToggleDestinationDetailsModal(openDest_id)
    }

    const handleToggleDestinationDetailsModal = async (dest_id?: string) => {
        if (dest_id && token && typeof token === 'string' && !keycloak.isTokenExpired()) {
            // Append dest_id to the current URL
            navigate(`/destinations/destination/${dest_id}/details`, { replace: false });
        }
        setDestDetailsModal(!destDetailsModal);
        if (destDetailsModal) setReloadDestinations(true);
    }

    const handleFilterState = (e: any) => {
        let tempArray: FilterTags[] = destinationFilter.enabled
        // active was toggled
        if (e.target.value === "active") {
            if (tempArray.map(a => a.id).includes("true")) {
                let removed: FilterTags[] = []
                for (var item in tempArray) {
                    if (tempArray[item].id !== "true") {
                        removed.push(tempArray[item])
                    }
                    setDestinationFilter((prevFilter: any) => ({
                        ...prevFilter,
                        [e.target.id]: removed
                    }))
                }
            } else {
                let newItem = {
                    name: "Active",
                    id: "true",
                    attribute_name: "enabled"
                }
                tempArray.push(newItem);
                setDestinationFilter((prevFilter: any) => ({
                    ...prevFilter,
                    [e.target.id]: tempArray
                }))
            }

        } else { // inactive was toggled
            if (tempArray.map(a => a.id).includes("false")) {
                let removed: FilterTags[] = []
                for (item in tempArray) {
                    if (tempArray[item].id !== "false") {
                        removed.push(tempArray[item])
                    }
                    setDestinationFilter((prevFilter: any) => ({
                        ...prevFilter,
                        [e.target.id]: removed
                    }))
                }
            } else {
                let newItem = {
                    name: "Inactive",
                    id: "false",
                    attribute_name: "enabled"
                }
                tempArray.push(newItem);
                setDestinationFilter((prevFilter: any) => ({
                    ...prevFilter,
                    [e.target.id]: tempArray
                }))
            }
        }
        setOffset(0);
        setReloadDestinations(true);
    }

    const handleChangeDestinationTypeSearchBar = (e: any) => {
        if (e.target.value.length >= 3 || e.target.value.length === 0) {
            loadDestinationTypesData(destinationTypesOffset, e.target.value);
        }
    }

    const handleLoadMoreDestinationTypes = () => {
        setDestinationTypesOffset(destinationTypesOffset + page_size);
        loadDestinationTypesData(destinationTypesOffset + page_size, "");
    }

    const handleFilterType = (e: any) => {
        let tempArray: FilterTags[] = destinationFilter.type__in
        if (tempArray.map(a => a.id).includes(e.target.value)) {
            let removed: FilterTags[] = []
            for (var item in tempArray) {
                if (tempArray[item].id !== e.target.value) {
                    removed.push(tempArray[item])
                }

            }
            setDestinationFilter((prevFilter: any) => ({
                ...prevFilter,
                [e.target.id]: removed
            }))
        } else {
            let newTag = {
                name: e.target.name,
                id: e.target.value
            }
            tempArray.push(newTag)
            setDestinationFilter((prevFilter: any) => ({
                ...prevFilter,
                [e.target.id]: tempArray
            }))
        }
        setOffset(0);
        setReloadDestinations(true);
    }

    const handleChangeOrganizationSearchBar = (e: any) => {
        if (e.target.value.length >= 3 || e.target.value.length === 0) {
            loadOrganizationsData(organizationOffset, e.target.value);
        }
    }

    const handleLoadMoreOrganizations = () => {
        setOrganizationOffset(organizationOffset + page_size);
        loadOrganizationsData(organizationOffset + page_size, "");
    }

    const handleFilterOwner = (e: any) => {
        let tempArray: FilterTags[] = destinationFilter.owner__in
        if (tempArray.map(a => a.id).includes(e.target.value)) {
            let removed: FilterTags[] = []
            for (var item in tempArray) {
                if (tempArray[item].id !== e.target.value) removed.push(tempArray[item])
            }
            setDestinationFilter((prevFilter: any) => ({
                ...prevFilter,
                [e.target.id]: removed
            }))
        } else {
            let newTag = {
                name: e.target.name,
                id: e.target.value
            }
            tempArray.push(newTag)
            setDestinationFilter((prevFilter: any) => ({
                ...prevFilter,
                [e.target.id]: tempArray
            }))
        }
        setOffset(0);
        setReloadDestinations(true);
    }

    const handleChangeDestinationUrlSearchBar = (e: any) => {
        if (e.target.value.length >= 3 || e.target.value.length === 0) {
            loadDestinationURlsData(destinationUrlOffset, e.target.value);
        }
    }

    const handleLoadMoreDestinationUrls = () => {
        setDestinationUrlOffset(destinationUrlOffset + page_size);
        loadDestinationURlsData(destinationUrlOffset + page_size, "");
    }

    const handleFilterEndpoint = (e: any) => {
        let tempArray: FilterTags[] = destinationFilter.base_url__in
        if (tempArray.map(a => a.id).includes(e.target.value)) {
            let removed: FilterTags[] = []
            for (var item in tempArray) {
                if (tempArray[item].id !== e.target.value) removed.push(tempArray[item])
            }
            setDestinationFilter((prevFilter: any) => ({
                ...prevFilter,
                [e.target.id]: removed
            }))
        } else {
            let newTag = {
                name: e.target.name,
                id: e.target.value
            }
            tempArray.push(newTag)
            setDestinationFilter((prevFilter: any) => ({
                ...prevFilter,
                [e.target.id]: tempArray
            }))
        }
        setOffset(0);
        setReloadDestinations(true);
    }

    const handleGlobalSearch = (searchSubStr: string) => {
        setDestinationFilter((prevFilter: any) => ({
            ...prevFilter,
            search: searchSubStr
        }));
        setOffset(0);
        setReloadDestinations(true);
    }

    const handleRemoveTag = (e: any) => {
        if (destinationFilter.type__in.map(a => a.id).includes(e)) {
            let tempArray: FilterTags[] = destinationFilter.type__in
            let removed: FilterTags[] = []
            for (var item in tempArray) {
                if (tempArray[item].id !== e) removed.push(tempArray[item])
            }
            setDestinationFilter((prevFilter: any) => ({
                ...prevFilter,
                type__in: removed
            }))
        } else if (destinationFilter.owner__in.map(a => a.id).includes(e)) {
            let tempArray: FilterTags[] = destinationFilter.owner__in
            let removed: FilterTags[] = []
            for (item in tempArray) {
                if (tempArray[item].id !== e) removed.push(tempArray[item])
            }
            setDestinationFilter((prevFilter: any) => ({
                ...prevFilter,
                owner__in: removed
            }))
        } else if ((destinationFilter.base_url__in.map(a => a.id).includes(e))) {
            let tempArray: FilterTags[] = destinationFilter.base_url__in
            let removed: FilterTags[] = []
            for (item in tempArray) {
                if (tempArray[item].id !== e) removed.push(tempArray[item])
            }
            setDestinationFilter((prevFilter: any) => ({
                ...prevFilter,
                base_url__in: removed
            }))
        } else {
            // enabled
            let tempArray: FilterTags[] = destinationFilter.enabled
            let removed: FilterTags[] = []
            for (item in tempArray) {
                if (tempArray[item].id !== e) removed.push(tempArray[item])
            }
            setDestinationFilter((prevFilter: any) => ({
                ...prevFilter,
                enabled: removed
            }))
        }
        setOffset(0);
        setReloadDestinations(true);
    }

    const handleResetAllFilters = (e: any) => {
        setDestinationFilter({
            base_url__in: [],
            enabled: [],
            type__in: [],
            owner__in: [],
            ordering: "",
            search: ""
        });
        setOffset(0);
        setReloadDestinations(true);
    }

    const handleSortTable = (orderBy: string, sortDirection: boolean) => {
        const ordering = sortDirection ? orderBy : "-" + orderBy;
        setDestinationFilter((prevFilter: any) => ({
            ...prevFilter,
            ordering: ordering
        }))
        setReloadDestinations(true);
    }

    const handlePageClick = (e: any) => {
        setOffset(e.selected * page_size);
        setReloadDestinations(true);
    }

    return !pageLoaded ?
        (
            <Spinner />
        ) : (
            <div>
                {addDestModal &&
                    <ManageDestinationModal
                        onToggleModal={handleToggleAddDestinationModal}
                    />
                }
                {destDetailsModal &&
                    <ManageDestinationDetails />
                }
                <DestinationsList
                    canAddDestination={canAddDestination}
                    reloadDestinations={reloadDestinations}
                    destinations={destinations}
                    destinationTypes={destinationTypes}
                    destinationUrls={destinationUrls}
                    loadingDestinationUrls={loadingDestinationUrls}
                    loadMoreDestinationUrls={loadMoreDestinationUrls}
                    onLoadMoreDestinationUrls={handleLoadMoreDestinationUrls}
                    organizations={organizations}
                    onAddDestination={handleToggleAddDestinationModal}
                    onViewDetails={handleToggleDestinationDetailsModal}
                    onChangeEndpoint={handleFilterEndpoint}
                    onChangeDestinationUrlSearchBar={handleChangeDestinationUrlSearchBar}
                    onChangeState={handleFilterState}
                    onChangeType={handleFilterType}
                    onChangeDestinationTypeSearchBar={handleChangeDestinationTypeSearchBar}
                    loadingDestinationTypes={loadingDestinationTypes}
                    loadMoreDestinationTypes={loadMoreDestinationTypes}
                    onLoadMoreDestinationTypes={handleLoadMoreDestinationTypes}
                    onChangeOwner={handleFilterOwner}
                    onChangeOwnerSearchBar={handleChangeOrganizationSearchBar}
                    loadingOrganizations={loadingOrganizations}
                    loadMoreOrganizations={loadMoreOrganizations}
                    onLoadMoreOrganizations={handleLoadMoreOrganizations}
                    onChangeGlobalSearch={handleGlobalSearch}
                    onRemoveTag={handleRemoveTag}
                    onResetFilters={handleResetAllFilters}
                    filters={destinationFilter.base_url__in
                        .concat(destinationFilter.type__in, destinationFilter.owner__in, destinationFilter.enabled)}
                    globalSearchStr={destinationFilter.search}
                    requestSort={handleSortTable}
                    onPageClicked={handlePageClick}
                    totalCount={totalCount}
                    pageLoaded={pageLoaded}
                    take={page_size}
                    offset={offset}
                />
            </div>
        );
};

export default DestinationsPage;